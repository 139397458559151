import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../../../../components/Button';
import ColorSchemaViewer from '../../components/ColorSchemaViewer';
import Select from '../../../../../components/Select';
import { openPopup } from '../../../popups/popupsSlice';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import { updateAreaUnit, updateLanguage } from '../../userSettingsSlice';
import {
  AreaUnit,
  Language,
} from '../../../../user/helpers/constants/user';
import { selectUpdates } from '../../userSettingsSelectors';
import {
  selectEmail,
  selectLanguage,
  selectMaxArea,
  selectTotalArea,
  selectAreaUnit,
} from '../../../../user/userSelectors';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import { selectColorSchemas } from '../../../../colorSchemas/colorSchemasSelectors';
import {
  addSchema,
  deleteSchema,
  getColorSchemas,
  updateSchema,
} from '../../../../colorSchemas/colorSchemasSlice';
import useDidMount from '../../../../../hooks/useDidMount';
import { getAvailableLanguages } from '../../../../../helpers/functions/utils/appConfig';

import './index.scss';

const getLanguageOptions = () => {
  const LANGUAGE_OPTIONS = [
    { value: Language.English, title: 'English' },
    { value: Language.German, title: 'Deutsch' },
    { value: Language.French, title: 'Français' },
    { value: Language.Dutch, title: 'Nederlands' },
    { value: Language.Ukrainian, title: 'Українська' },
    { value: Language.Russian, title: 'Русский' },
    { value: Language.PortugueseBrazil, title: 'Português (Brasil)' },
    { value: Language.Hungarian, title: 'Magyar' },
    { value: Language.Slovenian, title: 'Slovenski' },
    { value: Language.Bulgarian, title: 'Български' },
    { value: Language.Italian, title: 'Italiano' },
    { value: Language.Spanish, title: 'Español' },
  ];
  const availableLanguagesSet = new Set(getAvailableLanguages());

  return LANGUAGE_OPTIONS.filter((option) => {
    return availableLanguagesSet.has(option.value);
  });
};

const getAreaUnitOptions = (t) => {
  return [
    {
      value: AreaUnit.acres,
      title: t('general.area-unit.acres-unit'),
    },
    {
      value: AreaUnit.hectares,
      title: t('general.area-unit.hectares-unit'),
    },
  ];
};

const Panel = ({
  t,
  email,
  totalArea,
  colorSchemas,
  maxArea,
  language,
  areaUnit,
  onLanguageChange,
  onAreaUnitChange,
  onAddColorSchemaClick,
  onColorSchemaActionClick,
}) => {
  const dispatch = useDispatch();

  useDidMount(() => {
    dispatch(getColorSchemas());
  });

  return (
    <div className="user-settings-data-pers">
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.data')}
        </Typography>
        <ul className="list">
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.account')}
            </p>
            <p className="item__value">
              {email}
            </p>
          </li>
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.total-area')}
            </p>
            <p className="item__value">
              {totalArea}
            </p>
          </li>
          <li className="item">
            <p className="item__key">
              {t('user-settings.tabs.data-and-personalization.max-area')}
            </p>
            <p className="item__value">
              {maxArea}
            </p>
          </li>
        </ul>
      </div>
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.personalization')}
        </Typography>
        <ul className="list">
          <li className="item">
            <Select
              id="language"
              label={t('user-settings.tabs.data-and-personalization.language')}
              options={getLanguageOptions()}
              value={language}
              onChange={onLanguageChange}
            />
          </li>
          <li className="item">
            <Select
              id="areaUnit"
              label={t('user-settings.tabs.data-and-personalization.area-unit')}
              options={getAreaUnitOptions(t)}
              value={areaUnit}
              onChange={onAreaUnitChange}
            />
          </li>
        </ul>
      </div>
      <div className="user-settings-data-pers__section">
        <Typography variant="h6">
          {t('user-settings.tabs.data-and-personalization.color-schemes')}
        </Typography>
        <div className="color-panel">
          <div>
            {
              colorSchemas.map((colorSchema, index) => (
                <ColorSchemaViewer
                  key={index}
                  colorSchema={colorSchema}
                  onSchemaActionClick={onColorSchemaActionClick}
                />
              ))
            }
          </div>
          <Button
            startIcon={<AddIcon />}
            onClick={onAddColorSchemaClick}
          >
            {t('general.popups.color-schema.color-schema')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;
  const maxArea = selectMaxArea(state);
  const areaUnit = selectAreaUnit(state);
  const language = selectLanguage(state);
  const totalArea = selectTotalArea(state);
  const {
    language: updatedLanguage,
    areaUnit: updatedAreaUnit,
  } = selectUpdates(state) || {};
  const unit = getI18nAreaAmount(areaUnit).toLowerCase();

  return {
    t,
    email: selectEmail(state),
    colorSchemas: selectColorSchemas(state),
    language: updatedLanguage || language,
    areaUnit: updatedAreaUnit || areaUnit,
    totalArea: `${totalArea.toFixed(3)} ${unit}`,
    maxArea: `${maxArea.toFixed(3)} ${unit}`,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLanguageChange: (value) => {
    dispatch(updateLanguage(value));
  },
  onAreaUnitChange: (value) => {
    dispatch(updateAreaUnit(value));
  },
  onAddColorSchemaClick: () => {
    dispatch(openPopup({
      type: POPUPS.colorSchema,
      colorSchema: {},
      onConfirm: (title, colors) => {
        dispatch(addSchema({
          title,
          colors,
        }));
      },
    }));
  },
  onColorSchemaActionClick: (action, schema) => {
    if (action === 'delete') {
      dispatch(openPopup({
        type: POPUPS.deleteColorSchema,
        onConfirm: () => {
          dispatch(deleteSchema(schema.uuid));
        },
      }));
    } else if (action === 'edit') {
      dispatch(openPopup({
        type: POPUPS.colorSchema,
        colorSchema: schema,
        onConfirm: (title, colors) => {
          dispatch(updateSchema({
            uuid: schema.uuid,
            title,
            colors,
          }));
        },
      }));
    }
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Panel));
