import FieldProfiler from '../pages/FieldProfiler.jsx';
import ZonesOps from '../pages/ZonesOps.jsx';
import ZonesMaps from '../pages/ZonesMaps.jsx';
import ZonesMap from '../pages/ZonesMap.jsx';
import Field from '../pages/Field.jsx';
import CreateField from '../pages/CreateField.jsx';
import UploadData from '../pages/UploadData.jsx';
import UploadDataJohnDeere from '../pages/UploadDataJohnDeere.jsx';
import CompareLayers from '../pages/CompareLayers.jsx';
import UserIntegrations from '../pages/UserIntegrations';
import UserSettings from '../pages/UserSettings.jsx';
import ActivityLog from '../pages/ActivityLog';
import UserOrganizations from '../pages/UserOrganizations.jsx';
import UserFarms from '../pages/UserFarms.jsx';
import ExportData from '../pages/ExportData.jsx';
import WelcomeScreen from '../pages/WelcomeScreen';
import ExportDataJohnDeere from '../pages/ExportDataJohnDeere.jsx';
import SmartSampling from '../pages/SmartSampling.jsx';
import Homepage from '../pages/Homepage.jsx';
import OperationsWorkflow from '../pages/OperationsWorkflow';
import Authentication from '../pages/Authentication.jsx';
import BatchAnalytics from '../pages/BatchAnalytics';
import UserData from '../pages/UserData';
import Pricing from '../pages/Pricing';
import Sso from '../pages/Sso';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';

const routes = [
  {
    path: '/',
    component: Homepage,
    exact: true,
  },
  {
    path: '/auth/:view',
    component: Authentication,
    exact: true,
  },
  {
    path: getRootLink(PAGES_ROOTS.userData),
    component: UserData,
    exact: true,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.field)}/:farmUuid/:fieldUuid/:assetGroup?/:itemUuid?`,
    component: Field,
  },
  {
    path: getRootLink(PAGES_ROOTS.fields),
    component: FieldProfiler,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.compareLayers)}/:farmUuid/:fieldUuid`,
    component: CompareLayers,
  },
  {
    path: getRootLink(PAGES_ROOTS.createField),
    component: CreateField,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.zonesOps)}/:workflow/:farmUuid?/:fieldUuid?`,
    component: ZonesOps,
  },
  {
    path: getRootLink(PAGES_ROOTS.batchAnalytics),
    component: BatchAnalytics,
  },
  {
    path: getRootLink(PAGES_ROOTS.zonesMaps),
    component: ZonesMaps,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.zonesMap)}/:farmUuid/:fieldUuid/:uuid`,
    component: ZonesMap,
  },
  {
    path: getRootLink(PAGES_ROOTS.uploadDataJohnDeere),
    component: UploadDataJohnDeere,
  },
  {
    path: getRootLink(PAGES_ROOTS.upload),
    component: UploadData,
  },
  {
    path: getRootLink(PAGES_ROOTS.userIntegrations),
    component: UserIntegrations,
  },
  {
    path: getRootLink(PAGES_ROOTS.userSettings),
    component: UserSettings,
  },
  {
    path: getRootLink(PAGES_ROOTS.userActivityLog),
    component: ActivityLog,
  },
  {
    path: getRootLink(PAGES_ROOTS.userOrganizations),
    component: UserOrganizations,
  },
  {
    path: getRootLink(PAGES_ROOTS.userFarms),
    component: UserFarms,
  },
  {
    path: getRootLink(PAGES_ROOTS.exportDataJohnDeere),
    component: ExportDataJohnDeere,
  },
  {
    path: getRootLink(PAGES_ROOTS.exportData),
    component: ExportData,
  },
  {
    path: getRootLink(PAGES_ROOTS.operations),
    component: OperationsWorkflow,
  },
  {
    path: getRootLink(PAGES_ROOTS.welcomeScreen),
    component: WelcomeScreen,
  },
  {
    path: getRootLink(PAGES_ROOTS.smartSampling),
    component: SmartSampling,
  },
  {
    path: getRootLink(PAGES_ROOTS.pricing),
    component: Pricing,
  },
  {
    path: getRootLink(PAGES_ROOTS.sso),
    component: Sso,
  },
  {
    component: FieldProfiler,
  },
];

export default routes;
