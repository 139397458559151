export enum SatelliteFilterKey {
  cloudFilterValue = 'cloudFilterValue',
  monthFilterValue = 'monthFilterValue',
  yearFilterValue = 'yearFilterValue',
  providerFilterValue = 'providerFilterValue',
  ndviFilterValue = 'ndviFilterValue',
}

export const SATELLITE_FILTER_KEYS_I18N_MAP = {
  [SatelliteFilterKey.cloudFilterValue]: 'general.controls.satellite-filters.cloud',
  [SatelliteFilterKey.monthFilterValue]: 'general.controls.satellite-filters.month',
  [SatelliteFilterKey.yearFilterValue]: 'general.controls.satellite-filters.year',
  [SatelliteFilterKey.providerFilterValue]: 'general.controls.satellite-filters.provider',
  [SatelliteFilterKey.ndviFilterValue]: 'general.controls.satellite-filters.ndvi',
};

export const DEFAULT_FIELD_WORKFLOW_SATELLITE_FILTER = {
  [SatelliteFilterKey.cloudFilterValue]: 0,
  [SatelliteFilterKey.monthFilterValue]: [],
  [SatelliteFilterKey.yearFilterValue]: [],
  [SatelliteFilterKey.providerFilterValue]: [],
  [SatelliteFilterKey.ndviFilterValue]: [-1, 1],
};

export const DEFAULT_CREATE_ANALYSIS_SATELLITE_FILTER = {
  ...DEFAULT_FIELD_WORKFLOW_SATELLITE_FILTER,
  [SatelliteFilterKey.cloudFilterValue]: 1,
};
