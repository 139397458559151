import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import type { AttributeStatistics } from '../../../../../helpers/types/dataset';
import type {
  FullAttributeItem,
  NullableMinMaxCondition,
} from '../../types/ui';
import Button from '../../../../../components/Button';
import { isFullAttributeItemDisabled } from '../../helpers/functions/ui';
import MinMaxConditionsBaseItem from '../MinMaxConditionsBaseItem';

import './index.scss';

export default function MinMaxConditions({
  title,
  attributesItems,
  conditions,
  statistics = [],
  conditionMinInfoTooltip,
  conditionMaxInfoTooltip,
  classes,
  onAddConditionClick,
  onConditionAttributeChange,
  onConditionMinChange,
  onConditionMinIncludedChange,
  onConditionMaxChange,
  onConditionMaxIncludedChange,
  onConditionDelete,
}: {
  title?: string,
  attributesItems: FullAttributeItem[],
  conditions: NullableMinMaxCondition[],
  statistics?: AttributeStatistics[],
  conditionMinInfoTooltip?: string,
  conditionMaxInfoTooltip?: string,
  classes?: {
    label?: string,
  }
  onAddConditionClick: () => void,
  onConditionDelete: (i: number) => void,
  onConditionAttributeChange: (i: FullAttributeItem, index: number) => void,
  onConditionMinChange: (i: number | null, index: number) => void,
  onConditionMinIncludedChange?: (i: boolean, index: number) => void,
  onConditionMaxChange: (i: number | null, index: number) => void,
  onConditionMaxIncludedChange?: (i: boolean, index: number) => void,
}) {
  const { t } = useTranslation();

  const handleConditionMinChange = (value: string, index: number) => {
    const min = value ? parseFloat(value) : null;
    onConditionMinChange(min, index);
  };

  const handleConditionMaxChange = (value: string, index: number) => {
    const max = value ? parseFloat(value) : null;
    onConditionMaxChange(max, index);
  };

  const getConditionAttributeOptionDisabled = (
    attribute: FullAttributeItem,
    selectedAttribute?: FullAttributeItem,
  ) => {
    return isFullAttributeItemDisabled(conditions, attribute, selectedAttribute);
  };

  return (
    <div className="min-max-conditions">
      <div className="min-max-conditions__header">
        <Typography className="min-max-conditions__title">
          {title}
        </Typography>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddConditionClick}
        >
          {t('clean-calibrate.yield-popup.add-attribute')}
        </Button>
      </div>
      <div>
        {
          conditions.map((condition, index) => {
            const attributeStatistics = statistics.find((statistic) => {
              return statistic.attribute === condition.attribute;
            });

            return (
              <div
                key={index}
                className="min-max-conditions-item__wrapper"
              >
                <MinMaxConditionsBaseItem
                  condition={condition}
                  attributesItems={attributesItems}
                  attributeStatistics={attributeStatistics}
                  classes={classes}
                  title={t('clean-calibrate.yield-popup.tabs.calibrate.configure.attribute-title', {
                    number: index + 1,
                  })}
                  conditionMinInfoTooltip={conditionMinInfoTooltip}
                  conditionMaxInfoTooltip={conditionMaxInfoTooltip}
                  onConditionAttributeChange={(item) => onConditionAttributeChange(item, index)}
                  onConditionMinChange={(min) => handleConditionMinChange(min, index)}
                  onConditionMaxChange={(min) => handleConditionMaxChange(min, index)}
                  getConditionAttributeOptionDisabled={getConditionAttributeOptionDisabled}
                  {...onConditionMinIncludedChange
                    ? { onConditionMinIncludedChange: (value) => onConditionMinIncludedChange(value, index) }
                    : null}
                  {...onConditionMaxIncludedChange
                    ? { onConditionMaxIncludedChange: (value) => onConditionMaxIncludedChange(value, index) }
                    : null}
                />
                <IconButton
                  className="min-max-conditions-item__delete-button"
                  size="small"
                  onClick={() => onConditionDelete(index)}
                >
                  <DeleteSharpIcon />
                </IconButton>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
