export const POPUPS = {
  addDataLayer: 'add-data-layer',
  selectDataVariable: 'select-data-variable',
  exportEquationMap: 'export-equation-map',
  planetMetadataRequest: 'planet-metadata-request',
  planetMetadataStatus: 'planet-metadata-status',
  colorSchema: 'color-schema',
  deleteColorSchema: 'delete-color-schema',
  uploadFiles: 'upload-files',
  exportSourceData: 'export-source-data',
  cleanCalibrateYield: 'clean-calibrate-yield',
  exportFieldsToJD: 'export-fields-to-JD',
  reportIssue: 'report-issue',
  createFieldPopup: 'create-field-popup',
  copyPolygons: 'copy-polygons',
  closeUpload: 'close-upload',
  changeBackgroundLayer: 'change-background-layer',
  equationTestRun: 'equation-test-run',
  createEquation: 'create-equation',
  updateEquation: 'update-equation',
  assignVariable: 'assign-variable',
  finishGeneration: 'finish-generation',
  exportToJohnDeereAsWorkPlan: 'export-to-john-deere-as-work-plan',
  exportToJohnDeereAsFiles: 'export-to-john-deere-as-files',
  exportToJohnDeereAsAdmFile: 'export-to-john-deere-as-adm-file',
  exportBoundaryToJohnDeere: 'export-boundary-to-john-deere',
  deleteField: 'delete-field',
  deleteZonesMap: 'delete-zones-map',
  deleteUserFromOrganization: 'delete-user-from-organization',
  deleteSoilDataset: 'delete-soil-dataset',
  deleteYieldDataset: 'delete-yield-dataset',
  deleteAsAppliedDataset: 'delete-as-applied-dataset',
  deleteTopographyMap: 'delete-topography-map',
  delete3dMap: 'delete-3d-map',
  deleteFarm: 'delete-farm',
  deleteSharedFarm: 'delete-shared-farm',
  deleteEquationMap: 'delete-equation-map',
  deleteEquation: 'delete-equation',
  importSettingsJohnDeere: 'import-settings-john-deere',
};
