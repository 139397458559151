import { createSelector } from '@reduxjs/toolkit';

import { hasConnectionLinkToOrganization } from '../../jdOrganizations/helpers/functions';
import { selectFieldsAvailable } from '../../jdFields/jdFieldsSelectors';
import { prepareOperationsFilters, countOperationsWithFilters } from './helpers/functions/operations';

export const selectStep = ({ jdImport }) => jdImport.step;

export const selectHasSelectedOrganizations = ({ jdImport }) => jdImport.selectedOrganizations.length > 0;

export const selectSelectedOrganizations = ({ jdImport }) => jdImport.selectedOrganizations;

export const selectSelectedFields = ({ jdImport }) => jdImport.selectedFields;

export const selectHasSelectedFields = ({ jdImport }) => {
  return jdImport.selectedFields && jdImport.selectedFields.length > 0;
};

export const selectImportCountSelectedFields = ({ jdImport }) => {
  return jdImport.selectedFields ? jdImport.selectedFields.length : 0;
};

export const selectSelectedOrgsIds = ({ jdImport }) => {
  return jdImport.selectedOrganizations.map((org) => org.id);
};

export const selectImportComboBoxSelectedOrganizations = ({ jdImport }) => (
  (jdImport.selectedOrganizations || []).map((org) => ({ id: org.id, title: org.name }))
);

export const selectImportIsSelectedConnectedOrganizations = createSelector(
  [
    ({ jdOrganizations }) => jdOrganizations.orgs || [],
    ({ jdImport }) => jdImport.selectedOrganizations || [],
  ],
  (orgs, selectedOrganizations) => {
    const connectedOrganizations = orgs.filter((org) => !hasConnectionLinkToOrganization(org)).map((org) => org.id);
    return connectedOrganizations
      .filter((orgId) => !selectedOrganizations.find((selectedOrg) => selectedOrg.id === orgId)).length === 0;
  },
);

export const selectImportIsSelectedAvailableFields = createSelector(
  [
    selectFieldsAvailable,
    selectSelectedFields,
  ],
  (availableFields, selectedFields) => {
    let isSelectedAvailableFields = availableFields.length !== 0;
    availableFields.forEach((availableFieldSelection) => {
      if (!selectedFields.find((selectedField) => selectedField.id === availableFieldSelection.id)) {
        isSelectedAvailableFields = false;
      }
    });
    return isSelectedAvailableFields;
  },
);

export const selectImportInProgress = ({ jdImport }) => jdImport.importInProgress;

export const selectOperationsLoading = ({ jdImport }) => jdImport.operationsLoading;

export const selectImportType = ({ jdImport }) => jdImport.importType;

export const selectOperationsFilters = ({ jdImport }) => jdImport.operationsFilters;

export const selectOperations = ({ jdImport }) => jdImport.operations;

const selectSelectedFieldsFilteredOperationsCount = createSelector(
  selectOperationsFilters,
  selectSelectedFields,
  selectOperations,
  (operationsFilters, selectedFields, operations) => {
    const preparedFilters = prepareOperationsFilters(operationsFilters);

    return selectedFields.reduce((total, field) => {
      return total + countOperationsWithFilters(operations[field.id] ?? [], preparedFilters);
    }, 0);
  },
);

const selectAllFilteredOperationsCount = createSelector(
  selectOperationsFilters,
  selectOperations,
  (operationsFilters, operations) => {
    const preparedFilters = prepareOperationsFilters(operationsFilters);

    return Object.values(operations).reduce((total, fieldOperations) => {
      return total + countOperationsWithFilters(fieldOperations, preparedFilters);
    }, 0);
  },
);

export const selectFilteredOperationsCount = createSelector(
  selectHasSelectedFields,
  selectSelectedFieldsFilteredOperationsCount,
  selectAllFilteredOperationsCount,
  (hasSelectedFields, selectedFieldsFilteredOperationsCount, allFilteredOperationsCount) => {
    return hasSelectedFields ? selectedFieldsFilteredOperationsCount : allFilteredOperationsCount;
  },
);
