import { ICognitoStorage } from 'amazon-cognito-identity-js';

export class LocalStorage implements ICognitoStorage {
  private static instance: LocalStorage | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): LocalStorage {
    if (!LocalStorage.instance) {
      LocalStorage.instance = new LocalStorage();
    }

    return LocalStorage.instance;
  }

  setItem<T>(key: string, value: T): void {
    const serializedValue = JSON.stringify(value);
    window.localStorage.setItem(key, serializedValue);
  }

  getItem<T>(key: string): T | null {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  clear(): void {
    window.localStorage.clear();
  }
}
