import { readEnvVariable } from '.';
import { LocalStorage } from '../../../classes/cognitoStorage/localStorage';

const API_DOMAIN = 'api.geopard.tech';

export const getAPIDomain = () => {
  return API_DOMAIN;
};

export const getAwsClientId = () => {
  return readEnvVariable('AWS_CLIENT_ID');
};

export const getAWSConfig = () => {
  return {
    Auth: {
      identityPoolId: 'us-west-2:479d5c88-369e-4ebc-8782-f0acc6463491',
      region: 'us-west-2',
      userPoolId: 'us-west-2_fD8ohKI0Z',
      userPoolWebClientId: readEnvVariable('AWS_CLIENT_ID'),
      mandatorySignIn: true,
      oauth: {
        // Domain name
        domain: 'auth.geopard.tech',
        // Authorized scopes
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        // Callback URL
        redirectSignIn: readEnvVariable('AWS_REDIRECT_URL'),
        redirectSignOut: readEnvVariable('AWS_REDIRECT_URL'),
        // 'code' for Authorization code grant, 'token' for Implicit grant
        responseType: 'code',
      },
      storage: LocalStorage.getInstance(),
    },
    Storage: {
      bucket: 'boundaries-geopard',
      region: 'us-west-2',
    },
    SoilDatasetsStorage: {
      bucket: 'soildatasets-geopard',
      region: 'us-west-2',
    },
    YieldDatasetsStorage: {
      bucket: 'yielddatasets-geopard',
      region: 'us-west-2',
    },
    ZonesMapStorage: {
      bucket: 'zonesmaps-geopard',
      region: 'us-west-2',
    },
    Api: {
      domain: API_DOMAIN,
    },
    aws_appsync_graphqlEndpoint: readEnvVariable('AWS_GRAPHQL_ENDPOINT'),
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: readEnvVariable('AWS_GRAPHQL_KEY'),
    Analytics: {
      disabled: true,
    },
  };
};
