import { useTranslation } from 'react-i18next';

import { successNotify } from '../features/notifications/helpers/functions/notify';
import { POPUPS } from '../features/ui/popups/helpers/constants/popups';
import { openPopup } from '../features/ui/popups/popupsSlice';
import { useAppDispatch } from '../app/store/helpers/functions';

export default function useReportIssuePopup() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openReportIssuePopup = () => {
    dispatch(openPopup({
      type: POPUPS.reportIssue,
      onConfirm: () => {
        successNotify({
          message: t('general.notifications.report-issue-success'),
        });
      },
    }));
  };

  return {
    openReportIssuePopup,
  };
}
