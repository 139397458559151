import React from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

import './index.scss';

export default function InfoPanel({
  titleI18nKey,
  textI18nKey,
  items,
}: {
  titleI18nKey?: string,
  textI18nKey?:string,
  items?: string[],
}) {
  const { t } = useTranslation();

  return (
    <div className="info-panel">
      <InfoIcon
        className="info-panel__icon"
        fontSize="small"
      />
      <div>
        {titleI18nKey && (
          <Typography
            className='info-panel__title'
            variant='subtitle1'
          >
            {t(titleI18nKey)}
          </Typography>
        )}
        {textI18nKey && (
          <Typography
            className="info-panel__text"
          >
            {t(textI18nKey)}
          </Typography>
        )}
        {items && (
          <ul className='info-panel__list'>
            {items.map((item: string) => {
              return (
                <Trans
                  key={item}
                  parent='li'
                  className='info-panel__list-item'
                  i18nKey={item}
                >
                  Text
                  <span className="info-panel__list-item_light">
                    Text
                  </span>
                  Text
                </Trans>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
