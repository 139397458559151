import React, {
  Fragment,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import {
  createMuiTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Amplify } from '@aws-amplify/core';
import { Provider as ReduxProvider } from 'react-redux';

import './i18n';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { setupStore } from './app/store';
import BootstrapLoading from './components/Loading/BootstrapLoading';
import { init } from './features/ui/applicationShell/helpers/functions/sentry';
import { getAWSConfig } from './helpers/functions/utils/vendorConfigs/aws';
import { getPalette } from './helpers/functions/utils/appConfig';
import Amplitude from './helpers/classes/amplitude';
import NotificationsProvider from './features/notifications/containers/NotificationsProvider';
import routes from './router/routes';

const removeCognitoCookies = () => {
  const allCookies = document.cookie.split(';');

  allCookies.forEach((cookieStr) => {
    const cookie = cookieStr.trim();

    if (cookie.startsWith('Cognito')) {
      const cookieName = cookie.split('=')[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.geopard.tech;`;
    }
  });
};

init();
Amplify.configure(getAWSConfig());
Amplitude.configure();

removeCognitoCookies();

const palette = getPalette();
const theme = createMuiTheme({
  palette: {
    primary: {
      main: palette?.primary?.main || '#33691E',
      light: palette?.primary?.light || '#629749',
      dark: palette?.primary?.dark || '#003D00',
    },
    secondary: {
      main: palette?.secondary?.main || '#8E0000',
      light: palette?.secondary?.light || '#EF6443',
    },
    error: {
      main: palette?.error?.main || '#C62828',
    },
    text: {
      ...(palette?.text?.primary ? { primary: palette.text.primary } : {}),
      ...(palette?.text?.secondary ? { secondary: palette.text.secondary } : {}),
      ...(palette?.text?.disabled ? { disabled: palette.text.disabled } : {}),
    },
    action: {
      ...(palette?.action?.disabled ? { disabled: palette.action.disabled } : {}),
    },
  },
  zIndex: {
    modal: 1360, // to overflow drawer
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontSize: '20px',
      },
      body1: {
        fontSize: '14px',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 700,
      lg: 1200,
      xl: 1536,
    },
  },
});

const App = () => (
  <ReduxProvider store={setupStore()}>
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Suspense fallback={<BootstrapLoading />}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <HashRouter>
              <NotificationsProvider>
                <Switch>
                  {routes.map((route, i) => <Route key={i} {...route} />)}
                </Switch>
              </NotificationsProvider>
            </HashRouter>
          </MuiPickersUtilsProvider>
        </Suspense>
      </StylesProvider>
    </ThemeProvider>
  </ReduxProvider>
);

ReactDOM.render(
  <Fragment>
    <CssBaseline />
    <App />
  </Fragment>,
  document.getElementById('root'),
);
