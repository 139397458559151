import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Stepper from '../../../../../../../components/Stepper';
import AutocompleteSingle from '../../../../../../../components/AutocompleteSingle';
import OrganizationsTable from '../../../OrganizationsTable';
import {
  fetchOrganizationsJohnDeereWithNewOrgName,
} from '../../../../../../jdOrganizations/jdOrganizationsSlice';
import { resetFieldsJohnDeere } from '../../../../../../jdFields/jdFieldsSlice';
import {
  toggleOrganizationSelection,
  setOrganizationsSelection,
  setStep,
} from '../../../../jdImportSlice';
import {
  selectOrganizationsIsLoading,
  selectOrganizationsOrgName,
  selectOrganizationsList,
  selectConnectedOrganizations,
} from '../../../../../../jdOrganizations/jdOrganizationsSelectors';
import {
  selectHasSelectedOrganizations,
  selectSelectedOrganizations,
  selectImportIsSelectedConnectedOrganizations,
} from '../../../../jdImportSelectors';
import { getSteps } from '../common';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { getUserTotalArea } from '../../../../../../user/userSlice';
import RightAction from '../../RightAction';

import './index.scss';

const StepperSelectOrganization = () => {
  const dispatch = useDispatch();

  const isLoadingOrganizations = useSelector(selectOrganizationsIsLoading);
  const orgName = useSelector(selectOrganizationsOrgName);
  const organizations = useSelector(selectOrganizationsList);
  const connectedOrganizations = useSelector(selectConnectedOrganizations);

  const hasSelectedOrganizations = useSelector(selectHasSelectedOrganizations);
  const selectedOrganizations = useSelector(selectSelectedOrganizations);
  const isSelectedConnectedOrganizations = useSelector(selectImportIsSelectedConnectedOrganizations);

  useDidMount(() => {
    dispatch(getUserTotalArea());
  });

  const { t } = useTranslation();
  const steps = getSteps().slice(0, 1);

  const handleOrganizationChange = (row) => {
    const organization = organizations.find((org) => org.id === row.id);
    dispatch(toggleOrganizationSelection({ organization }));
    dispatch(resetFieldsJohnDeere());
  };

  const handleAllOrganizationsChange = (selected) => {
    dispatch(setOrganizationsSelection({ selected, organizations: connectedOrganizations }));
    dispatch(resetFieldsJohnDeere());
  };

  const handleChangeFilterByOrgName = (newOrgName) => {
    dispatch(fetchOrganizationsJohnDeereWithNewOrgName({ orgName: newOrgName }));
  };

  const handleNextClick = () => {
    dispatch(setStep({ step: 'selectData' }));
  };

  return (
    <Stepper
      classes={{
        root: 'john-deere__authorized-select-organization-step',
        backButton: 'back-button',
      }}
      activeStep={0}
      steps={steps}
      backHidden
    >
      <RightAction key="top-right" onActionClick={handleNextClick} />
      <Fragment key="panel">
        <AutocompleteSingle
          key="filter-by-name"
          placeholder={t('upload-data-john-deere.steps.selectOrganizations.search-by-name')}
          classes={{
            root: 'jd-import-select-orgs__name-input',
          }}
          disabled={isLoadingOrganizations}
          value={orgName}
          onChange={handleChangeFilterByOrgName}
        />
        {
          isLoadingOrganizations
            ? <div className="stepper-john-deere-panel__loader"><CircularProgress /></div>
            : (
              <OrganizationsTable
                typeSelection={{
                  type: 'multiple',
                  onRowCheckboxClick: handleOrganizationChange,
                  onHeadCheckboxClick: handleAllOrganizationsChange,
                  allRowsSelection: isSelectedConnectedOrganizations ? 2 : 0,
                  selectedOrganizations: hasSelectedOrganizations ? selectedOrganizations : [],
                }}
              />
            )
        }
      </Fragment>
    </Stepper>
  );
};

export default StepperSelectOrganization;
