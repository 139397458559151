export enum AreaUnit {
  hectares = 'HECTARES',
  acres = 'ACRES',
  squareMeters = 'SQUARE_METERS',
}

export enum UserRole {
  User = 'user',
  Advisor = 'advisor',
}

export enum CognitoGroup {
  Origin = 'Origin-group',
  PfeiferLangen = 'PfeiferLangen-group',
  Organization = 'Organization-group',
  Planet = 'Planet-group',
}

export enum Language {
  English = 'en',
  German = 'de',
  French = 'fr',
  Dutch = 'nl',
  Ukrainian = 'uk',
  Russian = 'ru',
  PortugueseBrazil = 'pt',
  Hungarian = 'hu',
  Slovenian = 'sl',
  Bulgarian = 'bg',
  Italian = 'it',
  Spanish = 'es',
}
