import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { selectSelectedFields, selectSelectedOrgsIds } from '../jdImportSelectors';
import { getFieldsOperations, setOperationsLoading } from '../jdImportSlice';
import { getJohnDeereFieldsIds } from '../../../jdFields/jdFieldsAPI';
import { errorNotify } from '../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

const PAGE_SIZE = 70;

const fetchAllFields = async (selectedOrgsIds: string[]) => {
  // @ts-expect-error
  const firstPage = await getJohnDeereFieldsIds({
    page: 1,
    pageSize: PAGE_SIZE,
    organizationsIds: selectedOrgsIds,
  });

  const totalPages = Math.ceil(firstPage.totalCount / PAGE_SIZE);
  const allFields = [...firstPage.fields];

  if (totalPages > 1) {
    const remainingPages = Array.from({ length: totalPages - 1 }, (_, i) => i + 2);
    const promises = remainingPages.map((page) => {
    // @ts-expect-error
      return getJohnDeereFieldsIds({
        page,
        pageSize: PAGE_SIZE,
        organizationsIds: selectedOrgsIds,
      });
    });

    const results = await Promise.all(promises);
    results.forEach((result) => allFields.push(...result.fields));
  }

  return allFields;
};

export default function useGetFieldsOperations() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedFields = useAppSelector(selectSelectedFields);
  const selectedOrgsIds = useAppSelector(selectSelectedOrgsIds);

  const exec = async () => {
    try {
      dispatch(setOperationsLoading(true));

      let fieldsToProcess = selectedFields;

      if (!selectedFields.length) {
        fieldsToProcess = await fetchAllFields(selectedOrgsIds);
      }

      const fields = fieldsToProcess
        // @ts-expect-error
        .filter((field) => field.area > 0)
        // @ts-expect-error
        .map(({ id, orgId }) => ({ id, orgId }));

      // @ts-expect-error
      await dispatch(getFieldsOperations({ fields })).unwrap();
    } catch (error) {
      errorNotify({
        error: new CustomError('[useGetFieldsOperations] Unable to get fields operations.', {
          cause: error,
        }),
        message: t('upload-data-john-deere.steps.selectData.notifications.get-fields-operations-error'),
        dispatch,
      });
    } finally {
      dispatch(setOperationsLoading(false));
    }
  };

  return {
    getFieldsOperations: exec,
  };
}
