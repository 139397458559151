import {
  endOfDay,
  subMonths,
  startOfDay,
  isValid,
  isWithinInterval,
} from 'date-fns';
import i18n from 'i18next';

/**
 *
 * @param milliseconds
 * @returns YYYY-MM-DD
 */
export const millisToDateString = (milliseconds: number): string => {
  return new Date(milliseconds)
    .toISOString()
    .substring(0, 10);
};

/**
 *
 * @param dateString YYYY-MM-DD
 * @returns milliseconds
 */
export const dateStringToMillis = (dateString: string): number => {
  return new Date(dateString).getTime();
};

/**
 *
 * @param date
 * @returns date string in user's default locale in format '3 letters for month + space + DD + space + YYYY'
 */
export const convertDateToDisplayFormat = (date: Date | string | null = '') => {
  if (!date) {
    return '';
  }

  const d = new Date(date);
  let result = '';

  // 3 letters for weekday + space + 3 letters for month + space
  if (isValid(d)) {
    const month = i18n.t(`general.date.months.${d.getMonth()}`).slice(0, 3);

    result = `${month} ${d.toDateString().slice(8)}`;
  }

  return result;
};

/**
 *
 * @param date
 * @returns date string in user's default locale
 */
export const formatDate = (date: string | Date = ''): string => {
  const d = new Date(date);

  if (!isValid(d)) {
    return '';
  }

  return d.toLocaleDateString([], {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

/**
 *
 * @param dateString
 * @returns time string in user's default locale
 */
export const formatTime = (dateString: string): string => {
  const d = new Date(dateString);

  if (!isValid(d)) {
    return '';
  }

  return d.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDateTime = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  return `${formatDate(dateString)} | ${formatTime(dateString)}`;
};

export const isDateWithinAllowedRange = (
  date: Date,
  monthsBackAllowed: number,
) => {
  const maxAllowedDate = new Date();
  const minAllowedDate = subMonths(maxAllowedDate, monthsBackAllowed);
  const interval = { start: startOfDay(minAllowedDate), end: endOfDay(maxAllowedDate) };

  return isWithinInterval(date, interval);
};

export const isDateValid = (date: Date | null):date is Date => {
  return isValid(date);
};

export const stringDateComparator = (a: string, b: string): number => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA < dateB) {
    return 1;
  } if (dateA > dateB) {
    return -1;
  }

  return 0;
};
