import parse from 'date-fns/parse';
import {
  endOfDay,
  isBefore,
  addMonths,
  differenceInMinutes,
} from 'date-fns';

import {
  OrderStatus,
  PricingPlan,
} from '../constants/order';

export const getOrderEndDate = (order) => {
  let result = null;

  if (order?.packageInfo?.endDate) {
    result = parse(order.packageInfo.endDate, 'yyyy-MM-dd', new Date());
  } else if (order?.packageInfo?.startDate && order.billingPeriodInMonths) {
    result = addMonths(
      parse(order.packageInfo.startDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  } else if (order?.orderDate && order.billingPeriodInMonths) {
    result = addMonths(
      parse(order.orderDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  }

  return result;
};

export const findLastPaidOrder = (orders = []) => {
  return orders.find((order) => order.status === OrderStatus.InvoicePaid);
};

export const isFreeTrialPlan = (order) => {
  return order?.plan === PricingPlan.FreeTrial;
};

export const isSoftLimitPlan = (order) => {
  return order?.plan === PricingPlan.Enterprise || order?.plan === PricingPlan.MonthlyPayAsYouGo;
};

export const isPayAsYouGoPlan = (order) => {
  return order?.plan === PricingPlan.MonthlyPayAsYouGo;
};

export const getOrderDaysLeft = (orderEndDate) => {
  if (!orderEndDate) {
    return 0;
  }

  const today = new Date();
  const endDateOfLastDay = endOfDay(orderEndDate);

  if (isBefore(endDateOfLastDay, today)) {
    return 0;
  }

  const diffInMinutes = differenceInMinutes(endDateOfLastDay, today);

  return Math.ceil(diffInMinutes / (24 * 60));
};
