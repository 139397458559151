import { getSearchParam } from '../../../../../helpers/navigation';
import { PricingPlan } from '../../../../user/helpers/constants/order';

export const getPlanParam = (search: string): PricingPlan => {
  return getSearchParam(search, 'plan') as PricingPlan;
};

export const getStripeCustomerIdParam = (search: string) => {
  return getSearchParam(search, 'customerId');
};
