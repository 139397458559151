export enum ProcessingType {
  fast = 'fast',
  clean = 'clean',
  calibrateClean = 'calibrateClean',
  calibrate = 'calibrate',
}

export enum Sequence {
  calibrateFirst = 'calibrateFirst',
  cleanFirst = 'cleanFirst',
}

export enum CalibrationType {
  pathwise = 'pathwise',
  averageTotal = 'averageTotal',
  conditional = 'conditional',
}

export enum CleaningType {
  smart = 'smart',
  usda = 'usda',
}

export enum AvgTotalCalibrationType {
  avg = 'avg',
  total = 'total',
}

export enum USDACleaningAttribute {
  yield = 'yield',
  delay = 'delay',
  velocity = 'velocity',
  swathWidth = 'swathWidth',
}
