import i18n from 'i18next';

import { Option } from '../../../../../components/ComboBox';
import { JohnDeereOperationType } from '../../../jdIntegration/helpers/constants';

const FOR_PERIOD_VALUE = 'for-period';
const ALL_VALUE = 'all';

const createFilterPeriods = () => {
  const DEFAULT_START_YEAR = 2015;
  const endYear = new Date().getFullYear();

  return Array.from(
    { length: endYear - DEFAULT_START_YEAR + 1 },
    (_, i) => {
      const year = endYear - i;
      return { title: year.toString(), value: year };
    },
  );
};

export const getOperationsTypesOptions = (types: Option[]): Option[] => {
  const allOperationsTypeOption = {
    title: i18n.t('integrations.jd.field-operations.types.all'),
    value: ALL_VALUE,
  };
  const operationsTypesOptions = [
    {
      title: i18n.t('integrations.jd.field-operations.types.application'),
      value: JohnDeereOperationType.application,
    },
    {
      title: i18n.t('integrations.jd.field-operations.types.harvest'),
      value: JohnDeereOperationType.harvest,
    },
    {
      title: i18n.t('integrations.jd.field-operations.types.seeding'),
      value: JohnDeereOperationType.seeding,
    },
  ];

  let options;

  if (types.length > 0) {
    options = types[0].value === ALL_VALUE ? [allOperationsTypeOption] : operationsTypesOptions;
  } else {
    options = [allOperationsTypeOption, ...operationsTypesOptions];
  }

  return options;
};

export const getPeriodsOptions = (
  periods: Option<number | string>[],
): Option<number | string>[] => {
  const allPeriodsOption = {
    title: i18n.t('integrations.jd.field-operations.types.all'),
    value: ALL_VALUE,
  };
  const periodsRangeOption = {
    title: i18n.t('upload-data-john-deere.import-settings.select-filters.period.for-period'),
    value: FOR_PERIOD_VALUE,
  };

  let options;
  const filterPeriods = createFilterPeriods();

  if (periods.length > 0) {
    if (periods[0].value === allPeriodsOption.value) {
      options = [allPeriodsOption];
    } else if (periods[0].value === periodsRangeOption.value) {
      options = [periodsRangeOption];
    } else {
      options = [...filterPeriods];
    }
  } else {
    options = [allPeriodsOption, ...filterPeriods, periodsRangeOption];
  }

  return options;
};

export const isPeriodRangeFilterActive = (periods: Option<number | string>[]) => {
  return periods.length === 1 && periods[0].value === FOR_PERIOD_VALUE;
};

export const getPeriodFromOptions = (periodTo: Option<number>) => {
  const filterPeriods = createFilterPeriods();
  let options;

  if (periodTo) {
    options = filterPeriods.filter((period) => period.value < periodTo.value);
  } else {
    options = filterPeriods;
  }

  return options;
};

export const getPeriodToOptions = (periodFrom: Option<number>) => {
  const filterPeriods = createFilterPeriods();
  let options;

  if (periodFrom) {
    options = filterPeriods.filter((period) => period.value > periodFrom.value);
  } else {
    options = filterPeriods;
  }

  return options;
};

export const isOperationsFiltersValid = (
  types: Option[],
  periods: Option<number | string>[],
  filterPeriodFrom: Option<number>,
  filterPeriodTo: Option<number>,
) => {
  return types.length > 0
    && periods.length > 0
    && (
      !isPeriodRangeFilterActive(periods)
        || (isPeriodRangeFilterActive(periods) && filterPeriodFrom && filterPeriodTo)
    );
};

export const prepareOperationsFilters = ({
  types,
  periods,
  periodFrom,
  periodTo,
}: {
  types: Option[];
  periods: Option<number | string>[];
  periodFrom: Option<number> | null;
  periodTo: Option<number> | null;
}): {
  fieldOperationTypes: JohnDeereOperationType[];
  cropSeasons: number[];
} => {
  const resultTypes = types
    .map((type) => type.value)
    .filter((type) => type !== ALL_VALUE);
  let resultPeriods = [];

  if (isPeriodRangeFilterActive(periods) && periodFrom && periodTo) {
    const start = periodFrom.value;
    const end = periodTo.value;

    resultPeriods = Array.from(
      { length: end - start + 1 },
      (_, i) => start + i,
    );
  } else {
    resultPeriods = periods
      .map((period) => period.value)
      .filter((period) => period !== ALL_VALUE);
  }

  return {
    fieldOperationTypes: resultTypes as JohnDeereOperationType[],
    cropSeasons: resultPeriods as number[],
  };
};

// @ts-expect-error
export const countOperationsWithFilters = (operations, preparedFilters) => {
  // @ts-expect-error
  return operations.reduce((fieldTotal, operation) => {
    if (
      preparedFilters.fieldOperationTypes.length !== 0
        && !preparedFilters.fieldOperationTypes.includes(operation.fieldOperationType)
    ) {
      return fieldTotal;
    }

    // @ts-expect-error
    const matchingSeasons = operation.seasons.filter((season) => {
      return preparedFilters.cropSeasons.length === 0
        || preparedFilters.cropSeasons.includes(season.cropSeason);
    });

    // @ts-expect-error
    return fieldTotal + matchingSeasons.reduce((seasonTotal, season) => {
      return seasonTotal + (season.totalCount ?? 0);
    }, 0);
  }, 0);
};
