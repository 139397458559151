import isBefore from 'date-fns/isBefore';

import { ApplicationStatus } from '../constants/application';
import { getOrderEndDate } from '../../../../user/helpers/functions/order';

export const calculateApplicationStatus = (paidOrder, hasGroupPermission) => {
  const paidOrderEndDate = getOrderEndDate(paidOrder);
  const today = new Date();

  if (
    !paidOrderEndDate
    || !hasGroupPermission
    || !isBefore(today, paidOrderEndDate)
  ) {
    return ApplicationStatus.blocked;
  }

  return ApplicationStatus.available;
};
