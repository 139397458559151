import * as amplitude from '@amplitude/analytics-browser';

import { ZonesOpsWorkflow } from '../../features/ui/zonesOps/helpers/constants/workflows';
import {
  AssetGroupType,
  AssetType,
} from '../constants/entities/asset';
import { BatchOperationWorkflow } from '../../features/ui/batchAnalytics/helpers/constants/workflows';
import { FieldOperation } from '../../features/ui/fieldWorkflow/helpers/constants/operation';
import { PAGES_ROOTS } from '../navigation';
import {
  FieldsListMode,
  MapView,
} from '../../features/ui/fieldProfiler/helpers/constants/view';
import { CreateFieldOperation } from '../../features/ui/createField/helpers/constants/operation';
import { WorkPlanType } from '../../features/ui/jdWorkPlan/helpers/constants/workPlan';
import {
  AnalysisMapType,
  VectorAnalysisMapType,
} from '../constants/entities/vectorAnalysisMap';
import { ExportType } from '../../features/exportData/helpers/constants/exportType';

export enum AmplitudeEvent {
  batchAnalyticsWorkflowSelected = 'Batch Analytics Workflow Selected',
  layersCompared = 'Layers Compared',
  createAnalysisMapWorkflowSelected = 'Create Analysis Map Workflow Selected',
  fieldOperationClicked = 'Field Operation Clicked',
  fieldAssetClicked = 'Field Asset Clicked',
  fieldsListViewSet = 'Fields List View Set',
  fieldsListModeSet = 'Fields List Mode Set',
  legendOpened = 'Legend Opened',
  appDrawerItemClicked = 'App Drawer Item Clicked',
  labelsPopupOpened = 'Labels Popup Opened',
  fieldLabelsAdded = 'Field Labels Added',
  fieldsLabelsAdded = 'Fields Labels Added',
  fieldsLabelsDeleted = 'Fields Labels Deleted',
  labelsCreated = 'Labels Created',
  organizationNameFilterChanged = 'Organization Name Filter Changed',
  zonesMapsFilterChanged = 'Zones Maps Filter Changed',
  zonesMapsListModeSet = 'Zones Maps List Mode Set',
  fieldsListFilterChanged = 'Fields List Filter Changed',
  navigatedToField = 'Navigated To Field',
  navigatedToZonesMap = 'Navigated To Zones Map',
  connectToJDOrganizationLinkClicked = 'Connect To John Deere Organization Link Clicked',
  createFieldOptionSelected = 'Create Field Option Selected',
  createEntityPopupOpened = 'Create Entity Popup Opened',
  entityCreated = 'Entity Created',
  deleteEntityPopupOpened = 'Delete Entity Popup Opened',
  entityDeleted = 'Entity Deleted',
  renameEntityPopupOpened = 'Rename Entity Popup Opened',
  entityRenamed = 'Entity Renamed',
  batchEntitiesDeletePopupOpened = 'Batch Entities Delete Popup Opened',
  batchEntitiesDeleted = 'Batch Entities Deleted',
  exportToJDPopupOpened = 'Export To John Deere Popup Opened',
  entityExportedToJD = 'Entity Exported To John Deere',
  batchEntitiesExportedToJD = 'Batch Entities Exported To John Deere',
  workPlanExportedToJD = 'Work Plan Exported To John Deere',
  entityDownloaded = 'Entity Downloaded',
  batchEntitiesDownloaded = 'Batch Entities Downloaded',
  selectExportTypePopupOpened = 'Select Export Type Popup Opened',
  ratesPreviewPopupOpened = 'Rates Preview Popup Opened',
  importFromJDButtonClicked = 'Import From John Deere Button Clicked',
  jdImportPeriodRangeFilterSelected = 'John Deere Import Period Range Filter Selected',
}

export type EntityType = 'field'
| 'zonesMap'
| 'yieldDataset'
| 'soilDataset'
| 'asAppliedDataset'
| 'topographyMap'
| 'user'
| 'farm'
| 'sharedFarm'
| 'threeDimensionalMap'
| 'equationMap'
| 'equation'
| 'satelliteImage';

type LabelsPopupType = 'manageFieldLabels'
| 'createLabels'
| 'addFieldsLabels'
| 'removeFieldsLabels';

export type JohnDeereExportType = 'asFiles'
| 'asMapsLayers'
| 'asYieldOperation'
| 'asWorkPlan';

type DownloadType = ExportType.multipolygons | ExportType.polygons | ExportType.isoxml;

export default class AmplitudeAnalytics {
  static trackEvent(event: AmplitudeEvent) {
    amplitude.track(event);
  }

  static trackBatchAnalyticsWorkflowSelected(properties: {
    workflow: BatchOperationWorkflow;
  }) {
    amplitude.track(AmplitudeEvent.batchAnalyticsWorkflowSelected, properties);
  }

  static trackLayersCompared(properties: { layersAmount: number }) {
    amplitude.track(AmplitudeEvent.layersCompared, properties);
  }

  static trackCreateAnalysisMapWorkflowSelected(properties: {
    page: string;
    workflow: ZonesOpsWorkflow;
  }) {
    amplitude.track(AmplitudeEvent.createAnalysisMapWorkflowSelected, properties);
  }

  static trackFieldOperationClicked(properties: {
    page: string;
    operation: FieldOperation;
  }) {
    amplitude.track(AmplitudeEvent.fieldOperationClicked, properties);
  }

  static trackFieldAssetClicked(properties: { type: AssetGroupType }) {
    amplitude.track(AmplitudeEvent.fieldAssetClicked, properties);
  }

  static trackLegendOpened(properties: {
    page: string;
    type: AssetType;
  }) {
    amplitude.track(AmplitudeEvent.legendOpened, properties);
  }

  static trackAppDrawerItemClicked(drawerItem: typeof PAGES_ROOTS[keyof typeof PAGES_ROOTS]) {
    amplitude.track(AmplitudeEvent.appDrawerItemClicked, {
      'drawer-item': drawerItem,
    });
  }

  static trackFieldsListViewSet(properties: { view: MapView }) {
    amplitude.track(AmplitudeEvent.fieldsListViewSet, properties);
  }

  static trackFieldsListModeSet(properties: {
    mode: FieldsListMode;
  }) {
    amplitude.track(AmplitudeEvent.fieldsListModeSet, properties);
  }

  static trackZonesMapsListModeSet(properties: {
    mode: 'defaultMode' | 'selectionMode';
  }) {
    amplitude.track(AmplitudeEvent.zonesMapsListModeSet, properties);
  }

  static trackFieldsListFilterChanged(properties: {
    page: string;
    labelsAmount?: number;
    fieldName?: boolean;
    farmName?: boolean
  }) {
    amplitude.track(AmplitudeEvent.fieldsListFilterChanged, properties);
  }

  static trackOrganizationNameFilterChanged(properties: {
    page: string;
  }) {
    amplitude.track(AmplitudeEvent.organizationNameFilterChanged, properties);
  }

  static trackZonesMapsFilterChanged(properties: {
    page: string;
    labelsAmount?: number;
    fieldName?: boolean;
    farmName?: boolean;
    type?: AnalysisMapType;
    purpose?: VectorAnalysisMapType;
  }) {
    amplitude.track(AmplitudeEvent.zonesMapsFilterChanged, properties);
  }

  static trackCreateFieldOptionSelected(properties: { operation: CreateFieldOperation }) {
    amplitude.track(AmplitudeEvent.createFieldOptionSelected, properties);
  }

  static trackLabelsPopupOpened(properties: {
    page: string;
    type: LabelsPopupType;
  }) {
    amplitude.track(AmplitudeEvent.labelsPopupOpened, properties);
  }

  static trackLabelsCreated(properties: {
    page: string;
    labelsAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.labelsCreated, properties);
  }

  static trackFieldLabelsAdded(properties: {
    page: string;
    labelsAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.fieldLabelsAdded, properties);
  }

  static trackFieldsLabelsAdded(properties: {
    page: string;
    labelsAmount: number;
    fieldsAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.fieldsLabelsAdded, properties);
  }

  static trackFieldsLabelsDeleted(properties: {
    page: string;
    labelsAmount: number;
    fieldsAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.fieldsLabelsDeleted, properties);
  }

  static trackCreateEntityPopupOpened(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.createEntityPopupOpened, properties);
  }

  static trackEntityCreated(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.entityCreated, properties);
  }

  static trackDeleteEntityPopupOpened(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.deleteEntityPopupOpened, properties);
  }

  static trackEntityDeleted(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.entityDeleted, properties);
  }

  static trackBatchEntitiesDeletePopupOpened(properties: {
    page: string,
    entityType: EntityType,
  }) {
    amplitude.track(AmplitudeEvent.batchEntitiesDeletePopupOpened, properties);
  }

  static trackBatchEntitiesDeleted(properties: {
    page: string;
    entityType: EntityType,
    entitiesAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.batchEntitiesDeleted, properties);
  }

  static trackRenameEntityPopupOpened(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.renameEntityPopupOpened, properties);
  }

  static trackEntityRenamed(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.entityRenamed, properties);
  }

  static trackNavigatedToField(properties: {
    view: 'description' | 'fieldDetails';
    page: string;
  }) {
    amplitude.track(AmplitudeEvent.navigatedToField, properties);
  }

  static trackNavigatedToZonesMap(properties: {
    view: 'description' | 'rates';
    page: string;
  }) {
    amplitude.track(AmplitudeEvent.navigatedToZonesMap, properties);
  }

  static trackConnectToJDOrganizationLinkClicked(properties: {
    page: string;
  }) {
    amplitude.track(AmplitudeEvent.connectToJDOrganizationLinkClicked, properties);
  }

  static trackExportToJDPopupOpened(properties: {
    page: string;
    type: JohnDeereExportType;
  }) {
    amplitude.track(AmplitudeEvent.exportToJDPopupOpened, properties);
  }

  static trackEntityExportedToJD(properties: {
    page: string;
    entityType: EntityType;
    exportType: JohnDeereExportType;
  }) {
    amplitude.track(AmplitudeEvent.entityExportedToJD, properties);
  }

  static trackBatchEntitiesExportedToJD(properties: {
    page: string;
    entityType: EntityType;
    exportType: JohnDeereExportType;
    entitiesAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.batchEntitiesExportedToJD, properties);
  }

  static trackWorkPlanExportedToJD(properties: {
    page: string;
    workPlanType: WorkPlanType;
    chemical: boolean,
    fertilizer: boolean,
    tankMix: boolean,
    dryBlend: boolean,
    variety: boolean,
  }) {
    amplitude.track(AmplitudeEvent.workPlanExportedToJD, properties);
  }

  static trackEntityDownloaded(properties: {
    page: string;
    entityType: EntityType;
    downloadType: DownloadType;
    ratesOnly: boolean;
  }) {
    amplitude.track(AmplitudeEvent.entityDownloaded, properties);
  }

  static trackBatchEntitiesDownloaded(properties: {
    page: string;
    entityType: EntityType;
    downloadType: DownloadType;
    ratesOnly: boolean;
    entitiesAmount: number;
  }) {
    amplitude.track(AmplitudeEvent.batchEntitiesDownloaded, properties);
  }

  static trackSelectExportTypePopupOpened(properties: {
    page: string;
    entityType: EntityType;
  }) {
    amplitude.track(AmplitudeEvent.selectExportTypePopupOpened, properties);
  }

  static trackRatesPreviewPopupOpened(properties: {
    page: string;
  }) {
    amplitude.track(AmplitudeEvent.ratesPreviewPopupOpened, properties);
  }

  static trackImportFromJDButtonClicked(properties: {
    fieldsSelected: boolean;
  }) {
    amplitude.track(AmplitudeEvent.importFromJDButtonClicked, properties);
  }

  static trackJDImportPeriodRangeFilterSelected(properties: {
    periodRange: boolean;
  }) {
    amplitude.track(AmplitudeEvent.jdImportPeriodRangeFilterSelected, properties);
  }
}
