import React, { ReactElement } from 'react';

import AppShellHeaderComponent from '../../components/Header';
import { signOut } from '../../../../user/userSlice';
import {
  isApplicationShellReportIssueEnabled,
} from '../../../../../helpers/functions/utils/appConfig';
import PricingPlanStatus from '../PricingPlanStatus';
import { useAppDispatch } from '../../../../../app/store/helpers/functions';
import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';

export default function AppShellHeader({
  text,
  secondaryText,
  withBackAction,
  onBackButtonClick,
}: {
  text: ReactElement,
  secondaryText: ReactElement,
  withBackAction?: boolean,
  onBackButtonClick?: () => void,
}) {
  const dispatch = useAppDispatch();
  const { openReportIssuePopup } = useReportIssuePopup();

  const handleSignOutClick = () => {
    dispatch(signOut());
  };

  return (
    <AppShellHeaderComponent
      withReportIssue={isApplicationShellReportIssueEnabled()}
      text={text}
      secondaryText={secondaryText}
      withBackAction={withBackAction}
      onBackButtonClick={onBackButtonClick}
      onLogOutClick={handleSignOutClick}
      onReportIssueClick={() => openReportIssuePopup()}
    >
      <PricingPlanStatus key="pricing-plan-status" />
    </AppShellHeaderComponent>
  );
}
