import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import UserDataRedirector from '../router/redirectors/UserData';
import PricingRedirector from '../router/redirectors/Pricing';
import Stepper from '../features/ui/jdImport/containers/ImportDataStepper';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import { getDocumentTitle } from '../helpers';
import useDidMount from '../hooks/useDidMount';
import { reset } from '../features/ui/jdImport/jdImportSlice';
import { resetStateJohnDeere } from '../features/jdFields/jdFieldsSlice';
import { isJohnDeereImportWorkflowEnabled } from '../helpers/functions/utils/appConfig';

const UploadDataJohnDeere = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useDidMount(() => {
    document.title = getDocumentTitle(t('upload-data-john-deere.title'));

    return () => {
      dispatch(reset());
      dispatch(resetStateJohnDeere());
    };
  });

  if (!isJohnDeereImportWorkflowEnabled()) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.fields)} />
    );
  }

  return (
    <AuthenticationRedirector>
      <UserDataRedirector>
        <PricingRedirector>
          <BaseTemplate
            headerProps={{
              text: t('upload-data-john-deere.title'),
            }}
          >
            <Stepper key="panel" />
          </BaseTemplate>
        </PricingRedirector>
      </UserDataRedirector>
    </AuthenticationRedirector>
  );
};

export default UploadDataJohnDeere;
