import i18n from 'i18next';

import {
  selectEmail,
  selectLanguage,
  selectMaxArea,
  selectReachedAreaLimit,
  selectUuid,
} from '../../user/userSelectors';
import {
  getUserData,
  setupUserIdentity,
} from '../../user/userSlice';
import { initialize as initializeProductFruits } from './helpers/functions/productFruits';
import { displayAreaLimitNotification } from './helpers/functions/notification';

let userDataFetcherResolve;
let userDataFetcher = new Promise((resolve) => {
  userDataFetcherResolve = resolve;
});

export const getUserDataFetcher = () => userDataFetcher;

export const setupApp = () => async (dispatch, getState) => {
  // useSubscriptionAvailable hook relies on userData to be available after app init.
  userDataFetcher = dispatch(getUserData())
    .then(({ payload }) => {
      if (userDataFetcherResolve) {
        userDataFetcherResolve();
        userDataFetcherResolve = null;
        return payload.data;
      }
    });

  try {
    await userDataFetcher;
  } catch (error) {
    console.error('Unable to fetch user data.', error);

    return;
  }

  const state = getState();
  const reachedAreaLimit = selectReachedAreaLimit(state);
  const maxArea = selectMaxArea(state);
  const userUuid = selectUuid(state);
  const userEmail = selectEmail(state);
  const language = selectLanguage(state);

  initializeProductFruits({
    userEmail,
    userUuid,
    language,
  });

  await i18n.changeLanguage(selectLanguage(state));

  dispatch(setupUserIdentity());

  if (reachedAreaLimit && maxArea > 0) {
    displayAreaLimitNotification();
  }
};
