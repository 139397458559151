import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import AmplitudeAnalytics from '../../../../../../../helpers/classes/amplitudeAnalytics';
import ComboBox, { Option } from '../../../../../../../components/ComboBox';
import Button from '../../../../../../../components/Button';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store/helpers/functions';
import {
  selectOperationsFilters,
  selectOperationsLoading,
  selectFilteredOperationsCount,
} from '../../../../jdImportSelectors';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import {
  getOperationsTypesOptions,
  getPeriodsOptions,
  isOperationsFiltersValid,
  getPeriodFromOptions,
  getPeriodToOptions,
  isPeriodRangeFilterActive,
} from '../../../../helpers/functions/operations';
import {
  setOperationsPeriodFromFilter,
  setOperationsPeriodToFilter,
  setOperationsPeriodsFilter,
  setOperationsTypesFilter,
} from '../../../../jdImportSlice';

import './index.scss';

export default function SelectFiltersStep({
  onBack = () => {},
  onConfirm = () => {},
}: {
  onBack: () => void,
  onConfirm: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const operationsLoading = useAppSelector(selectOperationsLoading);
  const operationsCount = useAppSelector(selectFilteredOperationsCount);
  const {
    types,
    periods,
    periodFrom,
    periodTo,
  } = useAppSelector(selectOperationsFilters);

  const operationsTypesOptions = getOperationsTypesOptions(types);
  const filterPeriodOptions = getPeriodsOptions(periods);
  const periodRangeFilterDisplayed = isPeriodRangeFilterActive(periods);
  const filtersValid = isOperationsFiltersValid(types, periods, periodFrom, periodTo);
  const filterPeriodFromOptions = getPeriodFromOptions(periodTo);
  const filterPeriodToOptions = getPeriodToOptions(periodFrom);

  const handleFilterTypeChange = (_e: unknown, value: Option[]) => {
    dispatch(setOperationsTypesFilter({
      types: value,
    }));
  };

  const handleFilterPeriodChange = (_e: unknown, value: Option<number | string>[]) => {
    const periodRange = isPeriodRangeFilterActive(value);
    AmplitudeAnalytics.trackJDImportPeriodRangeFilterSelected({ periodRange });

    dispatch(setOperationsPeriodsFilter({
      periods: value,
    }));
  };

  const handleFilterPeriodFromChange = (_e: unknown, value: Option<number> | null) => {
    dispatch(setOperationsPeriodFromFilter({
      periodFrom: value,
    }));
  };

  const handleFilterPeriodToChange = (_e: unknown, value: Option<number> | null) => {
    dispatch(setOperationsPeriodToFilter({
      periodTo: value,
    }));
  };

  return (
    <>
      <DialogContent className="jd-import-settings-select-filters">
        {operationsLoading && <BackdropLoading />}
        <Typography>
          {t('upload-data-john-deere.import-settings.select-filters.description')}
        </Typography>
        {
          filtersValid
            && (
              <Typography>
                <Trans i18nKey="upload-data-john-deere.import-settings.select-filters.datasets-to-import"
                  values={{ count: operationsCount }}
                  components={{
                    1: <span className="jd-import-settings-select-filters__text_highlight-neutral" />,
                  }}
                />
              </Typography>
            )
        }
        <div className="jd-import-settings-select-filters__filters">
          <ComboBox
            classes={{ autocomplete: 'autocomplete' }}
            multiple
            limitTags={3}
            disableCloseOnSelect={false}
            title={t('upload-data-john-deere.import-settings.select-filters.type.label')}
            value={types}
            options={operationsTypesOptions}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={handleFilterTypeChange}
          />
          <ComboBox<number | string, true>
            classes={{ autocomplete: 'autocomplete' }}
            multiple
            limitTags={3}
            disableCloseOnSelect={false}
            title={t('upload-data-john-deere.import-settings.select-filters.period.label')}
            value={periods}
            options={filterPeriodOptions}
            getOptionSelected={(option, value) => option.value === value.value}
            onChange={handleFilterPeriodChange}
          />
          {
            periodRangeFilterDisplayed
              && (
                <div className="jd-import-settings-select-filters__period-range">
                  <ComboBox<number>
                    classes={{ autocomplete: 'autocomplete' }}
                    title={t('upload-data-john-deere.import-settings.select-filters.period.from')}
                    value={periodFrom}
                    disableCloseOnSelect={false}
                    options={filterPeriodFromOptions}
                    getOptionSelected={(option, value) => option.value === value?.value}
                    onChange={handleFilterPeriodFromChange}
                  />
                  <ComboBox<number>
                    classes={{ autocomplete: 'autocomplete' }}
                    title={t('upload-data-john-deere.import-settings.select-filters.period.to')}
                    value={periodTo}
                    disableCloseOnSelect={false}
                    options={filterPeriodToOptions}
                    getOptionSelected={(option, value) => option.value === value?.value}
                    onChange={handleFilterPeriodToChange}
                  />
                </div>
              )
          }
        </div>
      </DialogContent>
      <DialogActions className="jd-import-settings-popup__actions">
        <Button variant="outlined" onClick={onBack}>
          {t('general.stepper.back')}
        </Button>
        <Button
          disabled={!filtersValid}
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {t('general.popups.done')}
        </Button>
      </DialogActions>
    </>
  );
}
