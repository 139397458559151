import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { UserRole } from '../../../../user/helpers/constants/user';

import './index.scss';

const getRoleOptions = (t) => [
  {
    value: UserRole.User,
    label: t(`user-organizations.admin.users-list.${UserRole.User}`),
  },
  {
    value: UserRole.Advisor,
    label: t(`user-organizations.admin.users-list.${UserRole.Advisor}`),
  },
];

const UsersList = ({
  users,
  onDeleteClick = () => {},
  onRoleChange = () => {},
}) => {
  const { t } = useTranslation();
  const [rolesMap, setRolesMap] = useState(
    users.reduce((acc, user) => {
      acc[user.userUuid] = user.allFarms ? UserRole.Advisor : UserRole.User;

      return acc;
    }, {}),
  );

  const handleRoleChange = (user, value) => {
    const allFarms = value === UserRole.Advisor;

    setRolesMap({
      ...rolesMap,
      [user.userUuid]: value,
    });

    onRoleChange(user, allFarms);
  };

  return (
    <Table
      classes={{
        root: 'users-list',
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell
            classes={{
              root: 'users-list__cell',
            }}
          >
            {t('user-organizations.admin.users-list.user')}
          </TableCell>
          <TableCell
            classes={{
              root: 'users-list__cell',
            }}
          >
            {t('user-organizations.admin.users-list.role')}
          </TableCell>
          <TableCell
            classes={{
              root: 'users-list__cell',
            }}
          >
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          users.map((user) => {
            return (
              <TableRow key={user.userUuid}>
                <TableCell
                  classes={{
                    root: 'users-list__cell',
                  }}
                >
                  {user.email}
                </TableCell>
                <TableCell
                  classes={{
                    root: 'users-list__selector-cell',
                  }}
                >
                  <Select
                    classes={{
                      root: 'users-list__selector',
                    }}
                    disableUnderline
                    value={rolesMap[user.userUuid] || UserRole.User}
                    IconComponent={ExpandMoreIcon}
                    onChange={(event) => handleRoleChange(user, event.target.value)}
                  >
                    {
                      getRoleOptions(t).map((option) => {
                        return (
                          <MenuItem
                            classes={{
                              root: 'users-list__selector-option',
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </TableCell>
                <TableCell
                  classes={{
                    root: 'users-list__cell_icon',
                  }}
                  align="right"
                >
                  <IconButton onClick={() => onDeleteClick(user)}>
                    <DeleteOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        }
        {
          users.length === 0
            && (
              <TableRow>
                <TableCell
                  colSpan="4"
                  classes={{
                    root: 'users-list__no-data-cell',
                  }}
                >
                  <p className="users-list__no-data-cell-message">
                    {t('general.controls.no-results')}
                  </p>
                </TableCell>
              </TableRow>
            )
        }
      </TableBody>
    </Table>
  );
};

export default UsersList;
