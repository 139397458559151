import { RootState } from '../../../app/store/helpers/types';

export const selectDatasetUuid = ({ cleanCalibrate }: RootState) => cleanCalibrate.datasetUuid;

export const selectTab = ({ cleanCalibrate }: RootState) => cleanCalibrate.tab;

export const selectStep = ({ cleanCalibrate }: RootState) => cleanCalibrate.step;

export const selectIsProcessing = ({ cleanCalibrate }: RootState) => cleanCalibrate.isProcessing;

export const selectSequence = ({ cleanCalibrate }: RootState) => cleanCalibrate.sequence;

export const selectCalibrationType = ({ cleanCalibrate }: RootState) => cleanCalibrate.calibrate.type;

export const selectCalibrateCalibrationAttributes = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.calibrationAttributes;
};

export const selectCalibrateSmoothWindowSize = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.smoothWindowSize;
};

export const selectCalibratePathwiseCalibrationBasis = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.pathwiseCondition.calibrationBasis;
};

export const selectCalibratePathwiseSyntheticMachinePath = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.pathwiseCondition.syntheticMachinePath;
};

export const selectCalibrateAvgTotalConditions = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.avgTotalConditions;
};

export const selectCalibrateMinMaxConditions = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.calibrate.minMaxConditions;
};

export const selectCleaningType = ({ cleanCalibrate }: RootState) => cleanCalibrate.clean.type;

export const selectCleanTargetAttribute = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.clean.targetAttribute;
};

export const selectCleanExcludedAttributes = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.clean.excludedAttributes;
};

export const selectCleanMinMaxConditions = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.clean.minMaxConditions;
};

export const selectCleanMinMaxUSDAConditions = ({ cleanCalibrate }: RootState) => {
  return cleanCalibrate.clean.minMaxUSDAConditions;
};
