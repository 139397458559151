import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from '../../../../../components/Tabs';
import FastIcon from '../Icons/fast.svg';
import CleanIcon from '../Icons/clean.svg';
import CleanCalibrateIcon from '../Icons/cleanCalibrate.svg';
import CalibrateIcon from '../Icons/calibrate.svg';
import { ProcessingType } from '../../helpers/constants/ui';

import './index.scss';

export default function ProcessingTypesTabs({
  tab,
  onTabChange,
}: {
  tab: ProcessingType,
  onTabChange: (t: ProcessingType) => void,
}) {
  const { t } = useTranslation();

  const tabs = [
    {
      value: ProcessingType.fast,
      label: {
        primary: t('clean-calibrate.yield-popup.tabs.fast.processing-type.tab-title'),
        icon: <FastIcon />,
      },
    },
    {
      value: ProcessingType.clean,
      label: {
        primary: t('clean-calibrate.yield-popup.tabs.clean.processing-type.tab-title'),
        icon: <CleanIcon />,
      },
    },
    {
      value: ProcessingType.calibrate,
      label: {
        primary: t('clean-calibrate.yield-popup.tabs.calibrate.processing-type.tab-title'),
        icon: <CalibrateIcon />,
      },
    },
    {
      value: ProcessingType.calibrateClean,
      label: {
        primary: t('clean-calibrate.yield-popup.tabs.calibrate-clean.processing-type.tab-title'),
        icon: <CleanCalibrateIcon />,
      },
    },
  ];

  return (
    <Tabs
      classes={{
        root: 'processing-type-tabs',
      }}
      tabClasses={{
        root: 'processing-type-tabs__tab',
        wrapper: 'processing-type-tabs__tab-wrapper',
      }}
      tabs={tabs}
      value={tab}
      centered
      variant='fullWidth'
      onTabChange={(_event: unknown, value: ProcessingType) => onTabChange(value)}
    />
  );
}
