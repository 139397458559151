import React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { Typography } from '@material-ui/core';

import AreaLimitMessage from '../../../../../containers/AreaLimitMessage';
import { getI18nAreaAmount } from '../../../../user/helpers/functions/user';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';

import './index.scss';

export default function AreaLimitPopupPanel({
  areaToImport,
  availableArea,
  areaLimitReached,
  areaUnit,
}: {
  areaToImport: number,
  availableArea: number,
  areaLimitReached: boolean,
  areaUnit: AreaUnit,
}) {
  const areaUnitLabel = getI18nAreaAmount(areaUnit).toLowerCase();

  return (
    <div className={clsx('area-limit-popup-panel', { 'area-limit-popup-panel_error': areaLimitReached })}>
      <Typography>
        <Trans
          i18nKey="upload-data-john-deere.import-settings.area-limits.available-area"
          components={{
            1: <span className="area-limit-popup-panel__text_highlight-neutral" />,
          }}
          values={{
            area: convertNumberToFormattedString(availableArea.toFixed(2)),
            areaUnit: areaUnitLabel,
          }}
        />
      </Typography>
      <Typography>
        <Trans
          i18nKey="upload-data-john-deere.import-settings.area-limits.area-to-import"
          components={{
            1: <span className="area-limit-popup-panel__text_highlight-neutral" />,
          }}
          values={{
            area: convertNumberToFormattedString(areaToImport.toFixed(2)),
            areaUnit: areaUnitLabel,
          }}
        />
      </Typography>
      {
        areaLimitReached
          && (
            <Typography>
              <AreaLimitMessage />
            </Typography>
          )
      }
    </div>
  );
}
