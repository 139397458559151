export const YIELD_PATTERNS = [
  'yield',
  'yld',
  'DRYMATTER',
  'Mass_Yield',
  'Dry_Yield',
  'Wet_Yield',
  'WetMass',
];

export const MACHINE_ID_PATTERNS = [
  'MachineId',
  'Machine',
  'Pass_Num',
  'Region',
];

export const CLEAN_YIELD_ATTRIBUTES = [
  // 1 priority
  'WetMass',
  'Yld_Mass_D',
  'Dry_Yield',
  // 2 priority
  'VRYIELDVOL',
  'Yld_Mass_W',
  'Wet_Yield',
  // 3 priority
  'DRYMATTER',
  'Yld_Vol_Dr',
  'Mass_Yield',
  // 4 priority
  'Yld_Vol_We',
];

export const DELAY_PATTERNS = [
  'Duration',
  'Crop_Flw_M',
  'Duration_s',
  'Distance_f',
];

export const VELOCITY_PATTERNS = [
  'Speed',
  'VEHICLSPEE',
  'Speed_mph_',
];

export const SWATH_WIDTH_PATTERNS = [
  'Width',
  'SWATHWIDTH',
  'Swth_Wdth_',
];
