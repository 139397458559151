import i18n from 'i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { featureCollection } from '@turf/helpers';

import { transformFarms } from '../../field/helpers/functions/assets';
import { getFarmBBoxes } from './createFieldAPI';
import { saveBoundary } from '../../field/fieldAPI';
import { prepareBoundaryGeojson } from '../../field/helpers/functions/features';
import {
  processingNotify,
  closeProcessingNotification,
} from '../../notifications/notificationsSlice';
import {
  selectFarm,
  selectLabels,
  selectName,
} from './createFieldSelectors';
import {
  FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP,
  FieldStatusMessage,
} from '../../field/helpers/constants/field';
import { isInvalid } from '../../field/helpers/functions/field';
import { getUserTotalArea } from '../../user/userSlice';

const initialState = {
  name: '',
  farm: null,
  labels: [],
  drawn: {
    feature: featureCollection([]),
    error: false,
  },
};

export const fetchFarmBoundaries = createAsyncThunk(
  'createField/fetchFarmBoundaries',
  (farmUuid) => {
    return getFarmBBoxes(farmUuid)
      .then((farms) => {
        return transformFarms(farms)[0];
      })
      .catch((error) => {
        console.error('Unable to fetch farm boundaries.', error);
      });
  },
);

export const saveField = createAsyncThunk(
  'createField/saveField',
  (features, { dispatch, getState }) => {
    dispatch(processingNotify({
      message: i18n.t('create-field.notifications.field-verified'),
    }));

    const state = getState();
    const { uuid } = selectFarm(state);
    const name = selectName(state);
    const labels = selectLabels(state);
    const trimmedName = name.trim();
    const transformedLabels = labels.reduce((acc, label) => {
      acc[label.name] = label.value;

      return acc;
    }, {});

    return saveBoundary({
      name: trimmedName,
      farmUuid: uuid,
      geojson: prepareBoundaryGeojson(features),
      labels: JSON.stringify(transformedLabels),
    })
      .then((registeredField) => {
        dispatch(closeProcessingNotification());

        if (isInvalid(registeredField)) {
          const errorMessage = i18n.t(FIELD_STATUS_MESSAGES_TO_I18N_KEYS_MAP[registeredField.statusMessage]);

          if (registeredField.statusMessage === FieldStatusMessage.fieldExceedsAreaLimit) {
            dispatch(getUserTotalArea());
          }

          return {
            error: errorMessage,
          };
        }

        return {
          data: {
            uuid: registeredField.uuid,
            name: trimmedName,
          },
        };
      })
      .catch((error) => {
        console.error('Unable to save field.', error);
        dispatch(closeProcessingNotification());

        return {
          error: i18n.t('general.notifications.something-went-wrong'),
        };
      });
  },
);

export const createFieldSlice = createSlice({
  name: 'createField',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setDrawn(state, action) {
      state.drawn.feature = action.payload.feature;
      state.drawn.error = action.payload.error;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    resetFarm(state) {
      state.farm = null;
    },
    setLabels(state, action) {
      state.labels = action.payload;
    },
    removeLabel(state, action) {
      state.labels = state.labels.filter((label) => {
        return label.value !== action.payload.value
          && label.name !== action.payload.name;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarmBoundaries.fulfilled, (state, action) => {
        state.farm = action.payload;
      })
      .addCase(saveField.fulfilled, (state, action) => {
        if (action.payload.error) {
          return;
        }

        return initialState;
      });
  },
});

export const {
  reset,
  setName,
  resetFarm,
  setLabels,
  removeLabel,
  setDrawn,
} = createFieldSlice.actions;

export default createFieldSlice.reducer;
