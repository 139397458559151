import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import useDidMount from '../../../../../hooks/useDidMount';
import {
  selectPermissionedOrganizationAdmin,
  selectOwnedOrganizations,
  selectUuid,
  selectEmail,
  selectAreaUnit,
  selectLastPaidOrder,
} from '../../../../user/userSelectors';
import {
  selectActionsData,
  selectFilter,
  selectIsLoaded,
} from '../../userActivityLogSelectors';
import {
  fetchActivityLog,
  loadMoreActivityLogData,
  updateActivityLog,
  updateFilterActivityLog,
} from '../../userActivityLogSlice';
import RouterLink from '../../../../../components/Link';
import useReportIssuePopup from '../../../../../hooks/useReportIssuePopup';
import { getPricingPageLink } from '../../../../../helpers/navigation';
import { isPayAsYouGoPlan } from '../../../../user/helpers/functions/order';

import './index.scss';

const ActivitiesBreakdown = React.lazy(() => import('../../components/ActivitiesBreakdown'));
const REQUEST_INTERVAL = 15000;

const ActivityLog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const areaUnit = useSelector(selectAreaUnit);
  const filter = useSelector(selectFilter);
  const isLoaded = useSelector(selectIsLoaded);
  const email = useSelector(selectEmail);
  const userUuid = useSelector(selectUuid);
  const lastPaidOrder = useSelector(selectLastPaidOrder);
  const {
    actions,
    totalCount,
  } = useSelector(selectActionsData);
  const isAdmin = useSelector(selectPermissionedOrganizationAdmin);
  const organizations = useSelector(selectOwnedOrganizations)
    .map((org) => {
      return {
        uuid: org.uuid,
        title: org.name,
        users: org.users.map((user) => {
          return {
            uuid: user.userUuid,
            title: user.email,
          };
        }),
      };
    });
  const currentUserOption = {
    title: email,
    uuid: userUuid,
  };

  const { openReportIssuePopup } = useReportIssuePopup();

  const requestUsageData = () => {
    dispatch(fetchActivityLog());
  };

  const updateUsageData = () => {
    dispatch(updateActivityLog());
  };

  const onFilterChange = (f) => {
    dispatch(updateFilterActivityLog(f));
  };

  const loadMoreActions = () => {
    dispatch(loadMoreActivityLogData());
  };

  useDidMount(() => {
    const intervalId = setInterval(updateUsageData, REQUEST_INTERVAL);

    requestUsageData();

    return () => {
      clearInterval(intervalId);
    };
  });

  return (
    <div className="user-subscription activity-log">
      <Typography variant="h6">
        {t('activity-log.activities-breakdown')}
      </Typography>
      <Typography className="activity-log__description">
        {
          isPayAsYouGoPlan(lastPaidOrder)
            ? (
              <Trans
                i18nKey="activity-log.pay-as-you-go-breakdown-description"
                components={{
                  1: <Link className="link" component="button" variant="body2" onClick={openReportIssuePopup}/>,
                  2: <RouterLink target="_blank" to={getPricingPageLink()} />,
                }}
              />
            )
            : t('activity-log.breakdown-description')
        }
      </Typography>
      <ActivitiesBreakdown
        extended={isAdmin && organizations.length > 0}
        currentUserOption={currentUserOption}
        organizations={organizations}
        areaUnit={areaUnit}
        filter={filter}
        actions={actions}
        totalCount={totalCount}
        isLoaded={isLoaded}
        onFilterChange={onFilterChange}
        loadMoreActions={loadMoreActions}
      />
    </div>
  );
};

export default ActivityLog;
