import { Auth } from '@aws-amplify/auth';

import useDidMount from '../hooks/useDidMount';
import { LocalStorage as CognitoLocalStorage } from '../helpers/classes/cognitoStorage/localStorage';
import { getAwsClientId } from '../helpers/functions/utils/vendorConfigs/aws';

const ITEM_PREFIX = 'CognitoIdentityServiceProvider';

const getTokenKeys = (clientId: string, userId: string) => [
  `${ITEM_PREFIX}.${clientId}.${userId}.idToken`,
  `${ITEM_PREFIX}.${clientId}.${userId}.accessToken`,
  `${ITEM_PREFIX}.${clientId}.${userId}.refreshToken`,
  `${ITEM_PREFIX}.${clientId}.${userId}.clockDrift`,
  `${ITEM_PREFIX}.${clientId}.LastAuthUser`,
  `${ITEM_PREFIX}.${clientId}.${userId}.userData`,
];

const getAllTokens = (storage: CognitoLocalStorage, tokenKeys: ReturnType<typeof getTokenKeys>) => {
  return tokenKeys.reduce((acc, key) => ({
    ...acc,
    [key]: storage.getItem(key),
  }), {});
};

const getRedirectTo = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectTo = params.get('redirectTo');

  if (!redirectTo) {
    throw new Error('redirectTo parameter is required');
  }

  return decodeURIComponent(redirectTo);
};

const getOriginUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const originUrl = params.get('originUrl');

  if (!originUrl) {
    throw new Error('originUrl parameter is required');
  }

  return decodeURIComponent(originUrl);
};

const createRedirectUrl = (tokens: Record<string, string | null>) => {
  const redirectTo = getRedirectTo();
  const originUrl = getOriginUrl();
  const url = new URL(redirectTo);
  url.searchParams.append('originUrl', originUrl);
  url.hash = JSON.stringify(tokens);

  return url.toString();
};

export default function Sso() {
  useDidMount(async () => {
    const storage = CognitoLocalStorage.getInstance();
    const userInfo = await Auth.currentUserInfo();
    const clientId = getAwsClientId();
    const userId = userInfo?.username;
    let tokens = {};

    if (userId) {
      const tokenKeys = getTokenKeys(clientId, userId);
      tokens = getAllTokens(storage, tokenKeys);
    }

    const redirectUrl = createRedirectUrl(tokens);

    window.location.replace(redirectUrl);
  });

  return null;
}
