import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../router/redirectors/Authentication';
import { getDocumentTitle } from '../helpers';
import Panel from '../features/ui/welcomeScreen/containers/Panel';
import useDidMount from '../hooks/useDidMount';
import BaseTemplate from './BaseTemplate.jsx';
import { getBrandFullName } from '../helpers/functions/utils/appConfig';

export default function WelcomeScreen() {
  const { t } = useTranslation();
  const brandName = getBrandFullName();

  useDidMount(() => {
    document.title = getDocumentTitle(t('welcome-screen.header', { brandName }));
  });

  return (
    <AuthenticationRedirector>
      <BaseTemplate headerProps={{ text: t('welcome-screen.header', { brandName }) }}>
        <Panel key="panel" />
      </BaseTemplate>
    </AuthenticationRedirector>
  );
}
