import React, { Suspense } from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../../../../../components/Loading';
import Tabs from '../../../../../../components/Tabs';
import ScrollContainer from '../../../../../../components/ScrollContainer';
import getRoutesConfig from '../config';
import { getUserUnitArea } from '../../../../../../helpers';
import { MAX_FIELD_AREA } from '../../../../../../helpers/constants/areaLimits';
import {
  addFiles,
  removeFile,
  setFarm,
  setTab,
} from '../../../uploadDataSlice';
import { selectReachedAreaLimit } from '../../../../../user/userSelectors';
import { selectFarm, selectFiles, selectTab } from '../../../uploadDataSelectors';
import { AreaUnit } from '../../../../../user/helpers/constants/user';
import useDidMount from '../../../../../../hooks/useDidMount';
import { UploadType } from '../../../helpers/constants/upload';
import FarmSelect from '../../../../../farms/containers/FarmSelect';
import { getDataCompatibilityBlogPostLink } from '../../../../../../helpers/functions/utils/appConfig';
import { TransformedFarm } from '../../../../../farms/types/farm';

import './index.scss';

const DROPZONE_CONFIG = {
  [UploadType.fieldBoundaries]: {
    maxFileSize: 52428800, // 50MB
    allowedFormats: ['.zip', '.shp', '.dbf', '.shx', '.cpg', '.prj', '.kml', '.kmz'],
  },
  [UploadType.allTypes]: {
    maxFileSize: 0,
    allowedFormats: [],
  },
  [UploadType.soilData]: {
    maxFileSize: 41943040, // 40MB
    allowedFormats: ['.zip'],
  },
  [UploadType.yieldData]: {
    maxFileSize: 104857600, // 100MB
    allowedFormats: ['.zip'],
  },
  [UploadType.asApplied]: {
    maxFileSize: 31457280, // 30MB
    allowedFormats: ['.zip'],
  },
  [UploadType.machineryFormats]: {
    maxFileSize: 104857600, // 100MB
    allowedFormats: ['.zip'],
  },
};

const Dropzone = React.lazy(() => import('../../../components/Dropzone'));

const maxAcArea = getUserUnitArea(MAX_FIELD_AREA, AreaUnit.acres).toFixed(0);
const maxHaArea = getUserUnitArea(MAX_FIELD_AREA, AreaUnit.hectares).toFixed(0);

const getTabs = (
  config: { value: UploadType, disabled?: boolean, labelI18nKey: string }[],
  files: ReturnType<typeof selectFiles>,
) => {
  return config.map((entry) => {
    const filesForTab = files[entry.value];
    const countLabel = filesForTab?.length > 0 ? `(${filesForTab.length})` : '';

    return {
      value: entry.value,
      disabled: entry.disabled,
      label: {
        primary: `${i18n.t(entry.labelI18nKey)} ${countLabel}`,
      },
    };
  });
};

const getDescriptionComponent = (type: UploadType) => {
  switch (type) {
    case UploadType.fieldBoundaries:
      return (
        <>
          <Trans i18nKey="upload-data.field-boundaries.p1"></Trans>
          <p>{i18n.t('upload-data.field-boundaries.p2')}</p>
          <p>{i18n.t('upload-data.field-boundaries.p3')}</p>
          <>
            {i18n.t('upload-data.common.max-size', {
              size: 50,
            })} <Trans i18nKey="upload-data.field-boundaries.p4">
              {{ maxAcArea }} {{ maxHaArea }}
            </Trans>
          </>
        </>
      );
    case UploadType.soilData:
      return (
        <>
          <p>{i18n.t('upload-data.common.p1')}</p>
          <p>{i18n.t('upload-data.common.p2')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-type')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-symbols')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-count')}</p>
          <p>{i18n.t('upload-data.common.max-size', {
            size: 40,
          })}</p>
          <p>{i18n.t('upload-data.common.p4')}</p>
        </>
      );
    case UploadType.yieldData:
      return (
        <>
          <p>{i18n.t('upload-data.common.p1')}</p>
          <p>{i18n.t('upload-data.common.p2')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-type')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-symbols')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-count')}</p>
          <p>{i18n.t('upload-data.common.max-size', {
            size: 100,
          })}</p>
          <p>{i18n.t('upload-data.common.p4')}</p>
        </>
      );
    case UploadType.asApplied:
      return (
        <>
          <p>{i18n.t('upload-data.common.p1')}</p>
          <p>{i18n.t('upload-data.common.p2')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-type')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-symbols')}</p>
          <p>{i18n.t('upload-data.common.datasets-attributes-count')}</p>
          <p>{i18n.t('upload-data.common.max-size', {
            size: 30,
          })}</p>
          <p>{i18n.t('upload-data.common.p4')}</p>
        </>
      );
    case UploadType.machineryFormats:
      return (
        <>
          <p>{i18n.t('upload-data.common.p1')}</p>
          <p>
            {i18n.t('upload-data.machinery-formats.p2')}
            <br />
            {i18n.t('upload-data.machinery-formats.p3')}
          </p>
          {
            getDataCompatibilityBlogPostLink()
            && (
              <a
                className="link"
                target="_blank"
                href={getDataCompatibilityBlogPostLink()}
                rel="noopener noreferrer"
              >
                {i18n.t('upload-data.machinery-formats.p4')}
              </a>
            )
          }
          <p>{i18n.t('upload-data.common.max-size', {
            size: 100,
          })}</p>
          <p>{i18n.t('upload-data.machinery-formats.p5')}</p>
        </>
      );
    case UploadType.allTypes:
    default:
      return (
        <></>
      );
  }
};

const Base = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const tab = useSelector(selectTab);
  const farm = useSelector(selectFarm);
  const routesConfig = getRoutesConfig({
    isAreaLimitReached: useSelector(selectReachedAreaLimit),
  });
  const allFiles = useSelector(selectFiles);
  const files = allFiles[tab];

  useDidMount(() => {
    let entry = routesConfig.find(({ path }) => path === location.pathname);

    if (entry?.disabled) {
      entry = routesConfig.find(({ disabled }) => !disabled);

      if (entry) {
        history.replace(entry.path);
      }
    }

    dispatch(setTab(entry ? entry.value : tab));
  });

  const handleTabChange = (_event: object, tabValue: UploadType) => {
    const entry = routesConfig.find(({ value }) => value === tabValue);

    if (entry) {
      history.push(entry.path);
      dispatch(setTab(tabValue));
    }
  };
  const handleFilesChanged = (newFiles: File[]) => {
    dispatch(addFiles({
      type: tab,
      files: newFiles,
    }));
  };
  const handleFileRemoved = (id: string) => {
    dispatch(removeFile(id));
  };
  const handleFarmChange = (newFarm: TransformedFarm | null) => {
    dispatch(setFarm(newFarm));
  };

  const tabs = getTabs(routesConfig, allFiles);

  return (
    <ScrollContainer classes={{ root: 'upload-data-panel' }}>
      <div className="upload-data-panel__tabs-panel">
        <Tabs
          tabs={tabs}
          value={tab}
          onTabChange={handleTabChange}
        />
      </div>
      <Suspense fallback={<Loading />}>
        {
          tab === UploadType.fieldBoundaries
          && (
            <div className="upload-data-panel__params-panel">
              <FarmSelect
                required
                withCreateFarm
                selectedFarmUuid={farm?.uuid}
                onFarmChange={handleFarmChange}
              />
            </div>
          )
        }
        <Dropzone
          files={files}
          dropzoneConfig={{
            accept: DROPZONE_CONFIG[tab].allowedFormats,
            maxSize: DROPZONE_CONFIG[tab].maxFileSize,
          }}
          classes={{
            root: 'upload-data__dropzone',
          }}
          onFilesChanged={handleFilesChanged}
          onFileRemoved={handleFileRemoved}
        >
          <div key="description">
            {getDescriptionComponent(tab)}
          </div>
        </Dropzone>
      </Suspense>
    </ScrollContainer>
  );
};

export default Base;
