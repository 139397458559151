import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import AmplitudeAnalytics from '../../../../../../../helpers/classes/amplitudeAnalytics';
import Stepper from '../../../../../../../components/Stepper';
import AutocompleteSingle from '../../../../../../../components/AutocompleteSingle';
import ComboBox from '../../../../../../../components/ComboBox';
import Table from '../../../FieldsTable';
import {
  fetchFieldsJohnDeereWithNewOrganizationsIds,
  fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName,
} from '../../../../../../jdFields/jdFieldsSlice';
import { setStep } from '../../../../jdImportSlice';
import { openPopup } from '../../../../../popups/popupsSlice';
import {
  selectFieldsIsEmpty,
  selectFieldsIsLoading,
  selectFieldsFilterFieldName,
  selectFieldsHasAvailable,
} from '../../../../../../jdFields/jdFieldsSelectors';
import {
  selectSelectedOrgsIds,
  selectImportComboBoxSelectedOrganizations,
  selectHasSelectedFields,
  selectImportCountSelectedFields,
  selectImportInProgress,
} from '../../../../jdImportSelectors';
import { getSteps } from '../common';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { POPUPS } from '../../../../../popups/helpers/constants/popups';
import RightAction from '../../RightAction';

import './index.scss';

const StepperSelectData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fieldsEmpty = useSelector(selectFieldsIsEmpty);
  const fieldsLoading = useSelector(selectFieldsIsLoading);
  const filterFieldName = useSelector(selectFieldsFilterFieldName);
  const hasAvailableFields = useSelector(selectFieldsHasAvailable);
  const selectedOrganizations = useSelector(selectSelectedOrgsIds);
  const comboBoxSelectedOrganizations = useSelector(selectImportComboBoxSelectedOrganizations);
  const hasSelectedFields = useSelector(selectHasSelectedFields);
  const selectedFieldsCount = useSelector(selectImportCountSelectedFields);
  const importInProgress = useSelector(selectImportInProgress);

  const operationExecuting = fieldsLoading || importInProgress;
  const operationAvailable = hasAvailableFields || hasSelectedFields;
  const nextLabel = hasSelectedFields
    ? t('upload-data-john-deere.steps.selectData.buttons.import-selected', { count: selectedFieldsCount })
    : t('upload-data-john-deere.steps.selectData.buttons.import-all');

  const [filterOrganization, setFilterOrganization] = useState(null);

  const steps = getSteps();

  const handleOrganizationFilterChange = (_event, organization) => {
    setFilterOrganization(organization);
    if (organization) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIds({ organizationsIds: [organization.id] }));
    } else {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIds({ organizationsIds: selectedOrganizations }));
    }
  };

  const handleFieldNameFilterChange = (newFilterFieldName) => {
    if (filterOrganization) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: [filterOrganization.id],
        fieldName: newFilterFieldName,
      }));
    } else {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: selectedOrganizations,
        fieldName: newFilterFieldName,
      }));
    }
  };

  const handleNextClick = () => {
    AmplitudeAnalytics.trackImportFromJDButtonClicked({ fieldsSelected: hasSelectedFields });
    dispatch(openPopup({
      type: POPUPS.importSettingsJohnDeere,
    }));
  };

  const handleClickBack = () => {
    dispatch(setStep({ step: 'selectOrganizations' }));
  };

  useDidMount(() => {
    if (fieldsEmpty) {
      dispatch(fetchFieldsJohnDeereWithNewOrganizationsIdsAndFieldName({
        organizationsIds: selectedOrganizations,
        fieldName: filterFieldName,
      }));
    }
  });

  return (
    <Stepper
      activeStep={1}
      steps={steps}
      backDisabled={operationExecuting}
      nextDisabled={operationExecuting || !operationAvailable}
      onClickBack={handleClickBack}
    >
      <RightAction
        key="top-right"
        actionLabel={nextLabel}
        onActionClick={handleNextClick}
      />
      <Fragment key="panel">
        <div className="john-deere__authorized-nav">
          <ComboBox
            disabled={operationExecuting}
            title={t('upload-data-john-deere.steps.selectData.filter.organization.title')}
            disableCloseOnSelect={false}
            placeholder={t('general.controls.select')}
            options={comboBoxSelectedOrganizations}
            classes={{
              root: 'john-deere__authorized-nav-organization',
            }}
            getOptionSelected={(option, value) => (option.id === value.id)}
            value={filterOrganization}
            onChange={handleOrganizationFilterChange}
          />
          <AutocompleteSingle
            disabled={operationExecuting}
            placeholder={t('upload-data-john-deere.steps.selectData.filter.organization.search')}
            classes={{
              root: 'john-deere__authorized-nav-field-name',
            }}
            value={filterFieldName}
            onChange={handleFieldNameFilterChange}
          />
        </div>
        {
          operationExecuting
            && (
              <div className="john-deere__authorized-loader">
                <CircularProgress />
              </div>
            )
        }
        {
          !operationExecuting
            && (
              fieldsEmpty
                ? (
                  <div className="table__list-empty">
                    <span>{t('upload-data-john-deere.steps.selectData.table.no-fields')}</span>
                  </div>
                )
                : <Table />
            )
        }
      </Fragment>
    </Stepper>
  );
};

export default StepperSelectData;
