import i18n from 'i18next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  importData as importDataAPI,
  importAllData as importAllDataAPI,
  getFieldsOperations as getFieldsOperationsAPI,
} from './jdImportAPI';
import { errorNotify, successNotify } from '../../notifications/helpers/functions/notify';
import { getBrandName } from '../../../helpers/functions/utils/appConfig';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import { prepareOperationsFilters } from './helpers/functions/operations';
import {
  selectSelectedFields,
  selectSelectedOrgsIds,
  selectImportType,
  selectOperationsFilters,
} from './jdImportSelectors';
import { ImportType } from './helpers/constants/importType';
import {
  batchOperations,
  splitIntoChunks,
} from '../../../helpers/functions/utils/batchOperations';

const initialState = {
  step: 'selectOrganizations',
  selectedOrganizations: [],
  selectedFields: [],
  importInProgress: false,
  operationsLoading: false,
  importType: ImportType.fieldBoundaries,
  operationsFilters: {
    types: [],
    periods: [],
    periodFrom: null,
    periodTo: null,
  },
  /* { [key: fieldId]: operation } */
  operations: {},
};

const executeImportRequest = async (request, dispatch) => {
  try {
    const { status } = await request;

    if (status === '201') {
      successNotify({
        message: i18n.t('upload-data-john-deere.steps.selectData.notifications.start-import', {
          brandName: getBrandName(),
        }),
      });
    } else {
      errorNotify({
        error: new CustomError('[JD Import] Incorrect status.', {
          cause: { status },
        }),
        message: i18n.t('upload-data-john-deere.steps.selectData.notifications.not-imported'),
        dispatch,
      });
    }
  } catch (error) {
    errorNotify({
      error: new CustomError('[JD Import] Unable to execute import from JD.', {
        cause: error,
      }),
      message: i18n.t('upload-data-john-deere.steps.selectData.notifications.not-imported'),
      dispatch,
    });
  }
};

export const importData = createAsyncThunk(
  'jdImport/importData',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const selectedFields = selectSelectedFields(state);
    const importType = selectImportType(state);
    const operationsFilters = selectOperationsFilters(state);
    const fields = selectedFields.map(({ id, orgId }) => ({ id, orgId }));
    const preparedFilters = prepareOperationsFilters(operationsFilters);

    return executeImportRequest(importDataAPI({
      fields,
      importType,
      fieldOperationTypes: preparedFilters.fieldOperationTypes,
      cropSeasons: preparedFilters.cropSeasons,
    }), dispatch);
  },
);

export const importAllData = createAsyncThunk(
  'jdImport/importAllData',
  ({ fieldName }, { dispatch, getState }) => {
    const state = getState();
    const organizationsIds = selectSelectedOrgsIds(state);
    const importType = selectImportType(state);
    const operationsFilters = selectOperationsFilters(state);
    const preparedFilters = prepareOperationsFilters(operationsFilters);

    return executeImportRequest(importAllDataAPI({
      fieldName,
      organizationsIds,
      importType,
      fieldOperationTypes: preparedFilters.fieldOperationTypes,
      cropSeasons: preparedFilters.cropSeasons,
    }), dispatch);
  },
);

export const getFieldsOperations = createAsyncThunk(
  'jdImport/getFieldsOperations',
  async ({ fields }, { dispatch }) => {
    try {
      const BATCH_SIZE = 50;
      const fieldBatches = splitIntoChunks(fields, BATCH_SIZE);

      const results = await batchOperations(
        (batch) => getFieldsOperationsAPI({ fields: batch }),
        fieldBatches,
        4,
      );

      return results.flat();
    } catch (error) {
      errorNotify({
        error: new CustomError('[JD Import] Unable to get fields operations.', {
          cause: error,
        }),
        message: i18n.t('upload-data-john-deere.steps.selectData.notifications.get-fields-operations-error'),
        dispatch,
      });

      throw error;
    }
  },
);

export const jdImportSlice = createSlice({
  name: 'jdImport',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload.step;
    },
    reset(state) {
      return state.importInProgress ? state : initialState;
    },
    toggleOrganizationSelection(state, action) {
      const orgId = action.payload.organization.id;
      const index = state.selectedOrganizations.findIndex((org) => org.id === orgId);

      if (index === -1) {
        state.selectedOrganizations.push(action.payload.organization);
      } else {
        state.selectedOrganizations.splice(index, 1);
      }
    },
    setOrganizationsSelection(state, action) {
      const { organizations = [], selected } = action.payload;
      const currentIds = new Set(state.selectedOrganizations.map((org) => org.id));

      state.selectedOrganizations = selected
        ? [...state.selectedOrganizations, ...organizations.filter((org) => !currentIds.has(org.id))]
        : state.selectedOrganizations.filter((org) => !organizations.some((selectedOrg) => selectedOrg.id === org.id));
    },
    toggleFieldSelection(state, action) {
      const fieldId = action.payload.field.id;
      const index = state.selectedFields.findIndex((field) => field.id === fieldId);

      if (index === -1) {
        state.selectedFields.push(action.payload.field);
      } else {
        state.selectedFields.splice(index, 1);
      }
    },
    setFieldsSelection(state, action) {
      const { fields = [], selected } = action.payload;
      const currentIds = new Set(state.selectedFields.map((field) => field.id));

      state.selectedFields = selected
        ? [...state.selectedFields, ...fields.filter((field) => !currentIds.has(field.id))]
        : state.selectedFields.filter((field) => !fields.some((selectedField) => selectedField.id === field.id));
    },
    setImportType(state, action) {
      state.importType = action.payload.importType;
    },
    setOperationsTypesFilter(state, action) {
      state.operationsFilters.types = action.payload.types;
    },
    setOperationsPeriodsFilter(state, action) {
      state.operationsFilters.periods = action.payload.periods;
    },
    setOperationsPeriodFromFilter(state, action) {
      state.operationsFilters.periodFrom = action.payload.periodFrom;
    },
    setOperationsPeriodToFilter(state, action) {
      state.operationsFilters.periodTo = action.payload.periodTo;
    },
    setOperationsLoading(state, action) {
      state.operationsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFieldsOperations.fulfilled, (state, action) => {
        action.payload.forEach(({ id, fieldOperations }) => {
          state.operations[id] = fieldOperations;
        });
        state.operationsLoading = false;
      })
      .addCase(getFieldsOperations.rejected, (state) => {
        state.selectedFields.forEach((field) => {
          state.operations[field.id] = [];
        });
        state.operationsLoading = false;
      })
      .addCase(getFieldsOperations.pending, (state) => {
        state.operationsLoading = true;
      })
      .addMatcher(
        ({ type }) => type === importData.pending.type
          || type === importAllData.pending.type,
        (state) => {
          state.importInProgress = true;
        },
      )
      .addMatcher(
        ({ type }) => type === importData.fulfilled.type
          || type === importAllData.fulfilled.type,
        () => initialState,
      );
  },
});

export const {
  setStep,
  reset,
  toggleOrganizationSelection,
  setOrganizationsSelection,
  toggleFieldSelection,
  setFieldsSelection,
  setImportType,
  setOperationsTypesFilter,
  setOperationsPeriodsFilter,
  setOperationsPeriodFromFilter,
  setOperationsPeriodToFilter,
  setOperationsLoading,
} = jdImportSlice.actions;

export default jdImportSlice.reducer;
