import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../app/store/helpers/functions';
import Button from '../../../../../../components/Button';
import AvailableAreaText from '../../../../../../containers/AvailableAreaText';
import { selectHasSelectedOrganizations } from '../../../jdImportSelectors';
import {
  selectOrganizationsIsLoading,
} from '../../../../../jdOrganizations/jdOrganizationsSelectors';

import './index.scss';

export default function RightAction({
  actionLabel,
  onActionClick,
}: {
  actionLabel: string;
  onActionClick: () => void;
}) {
  const { t } = useTranslation();

  const hasSelectedOrganizations = useAppSelector(selectHasSelectedOrganizations);
  const organizationsLoading = useAppSelector(selectOrganizationsIsLoading);

  const actionDisabled = !hasSelectedOrganizations || organizationsLoading;

  return (
    <div className="jd-import-stepper__right-action">
      <AvailableAreaText />
      <Button
        variant="contained"
        color="primary"
        disabled={actionDisabled}
        onClick={onActionClick}
      >
        {actionLabel || t('general.stepper.next')}
      </Button>
    </div>
  );
}
