import { VIEWS as ZONES_MAP_VIEWS } from '../features/ui/zonesMap/helpers/constants/view';
import { AssetGroupType } from './constants/entities/asset';

export const PAGES_ROOTS = {
  homepage: '',
  auth: 'auth',
  fields: 'fields',
  field: 'field',
  zonesOps: 'zones-ops',
  zonesMaps: 'zones-maps',
  zonesMap: 'zones-map',
  user: 'user',
  userIntegrations: 'user/integrations',
  userIntegrationsJD: 'user/integrations/jd',
  userSettings: 'user/settings',
  userActivityLog: 'user/activity-log',
  userOrganizations: 'user/organizations',
  userFarms: 'user/farms',
  createField: 'create-field',
  compareLayers: 'compare-layers',
  create3d: 'create-3d',
  exportData: 'export',
  exportDataJohnDeere: 'export/to-john-deere-ops-center',
  upload: 'upload',
  uploadDataJohnDeere: 'upload/from-john-deere-ops-center',
  uploadAll: 'upload/all-types',
  uploadFieldBoundaries: 'upload/field-boundaries',
  uploadSoilData: 'upload/soil-data',
  uploadYieldData: 'upload/yield-data',
  uploadAsApplied: 'upload/as-applied',
  uploadMachineryFormats: 'upload/machinery-formats',
  welcomeScreen: 'welcome-screen',
  restrictedAccess: 'restricted-access',
  smartSampling: 'smart-sampling',
  operations: 'operations-log',
  batchAnalytics: 'batch-analytics',
  userData: 'user-data',
  pricing: 'pricing',
  sso: 'sso',
};

export const SEARCH_PARAMS = {
  [PAGES_ROOTS.zonesOps]: {
    fromField: 'fromField',
  },
};

export const getRootLink = (pathname) => {
  return `/${pathname}`;
};

export const getAuthLink = (view) => {
  return `/${PAGES_ROOTS.auth}/${view}`;
};

export const getFieldLink = (farmUuid, fieldUuid, searchParams = '') => {
  if (farmUuid && fieldUuid) {
    return `/${PAGES_ROOTS.field}/${farmUuid}/${fieldUuid}${searchParams}`;
  }

  return '';
};

export const getFieldsLink = (searchParams = '') => {
  return searchParams ? `/${PAGES_ROOTS.fields}?${searchParams}` : `/${PAGES_ROOTS.fields}`;
};

const getFieldAssetGroupLink = (farmUuid, fieldUuid, assetGroup, searchParams = '') => {
  if (farmUuid && fieldUuid && assetGroup) {
    return `${getFieldLink(farmUuid, fieldUuid)}/${assetGroup}${searchParams}`;
  }
  return '';
};

export const getFieldAssetItemLink = (farmUuid, fieldUuid, assetGroup, itemUuid, searchParams = '') => {
  if (farmUuid && fieldUuid && assetGroup && itemUuid) {
    return `${getFieldAssetGroupLink(farmUuid, fieldUuid, assetGroup)}/${itemUuid}${searchParams}`;
  }
  return '';
};

export const getZonesOpsLink = (farmUuid, fieldUuid, workflow, fromField) => {
  let result = `/${PAGES_ROOTS.zonesOps}`;

  if (workflow) {
    result += `/${workflow}`;
  }

  if (farmUuid && fieldUuid) {
    result += `/${farmUuid}/${fieldUuid}`;
  }

  if (fromField) {
    result += `?${SEARCH_PARAMS[PAGES_ROOTS.zonesOps].fromField}=${fromField}`;
  }

  return result;
};

export const getVectorAnalysisMapLink = (farmUuid, fieldUuid, itemUuid) => {
  if (farmUuid && fieldUuid && itemUuid) {
    return getFieldAssetItemLink(farmUuid, fieldUuid, AssetGroupType.vectorAnalysisMaps, itemUuid);
  }
  return '';
};

export const getEquationMapLink = (farmUuid, fieldUuid, itemUuid) => {
  if (farmUuid && fieldUuid && itemUuid) {
    return getFieldAssetItemLink(farmUuid, fieldUuid, AssetGroupType.equationMaps, itemUuid);
  }
  return '';
};

export const getZonesMapLink = (farmUuid, fieldUuid, uuid) => {
  if (farmUuid && fieldUuid && uuid) {
    return `/${PAGES_ROOTS.zonesMap}/${farmUuid}/${fieldUuid}/${uuid}`;
  }

  return '';
};

export const getZonesMapRatesLink = (farmUuid, fieldUuid, uuid) => {
  return `${getZonesMapLink(farmUuid, fieldUuid, uuid)}?view=${ZONES_MAP_VIEWS.rates}`;
};

export const getCompareLayersLink = (farmUuid, fieldUuid) => {
  return `/${PAGES_ROOTS.compareLayers}/${farmUuid}/${fieldUuid}`;
};

export const get3dMapLink = (farmUuid, fieldUuid, itemUuid) => {
  if (farmUuid && fieldUuid && itemUuid) {
    return getFieldAssetItemLink(farmUuid, fieldUuid, AssetGroupType.threeDimensionalMaps, itemUuid);
  }
  return '';
};

export const getPricingPageLink = (params) => {
  return `/${PAGES_ROOTS.pricing}?${new URLSearchParams(params)}`;
};

export const getSearchParam = (search, param, defaultValue = '') => {
  const searchParams = new URLSearchParams(search);

  return searchParams.get(param) || defaultValue;
};

export const updateSearchParam = (location, history, params) => {
  const searchParams = new URLSearchParams(location.search);

  params.forEach(({ name, value }) => {
    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }
  });

  history.push({
    search: searchParams.toString(),
  });
};

export const openInNewTab = (url) => {
  window.open(url, '_blank')?.focus();
};
