import React from 'react';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { useAppSelector } from '../../app/store/helpers/functions';
import { selectAreaUnit, selectAvailableArea } from '../../features/user/userSelectors';
import { getI18nAreaUnit } from '../../helpers';
import { convertNumberToFormattedString } from '../../helpers/markup';
import Tooltip from '../../components/Tooltip';

import './index.scss';

export default function AvailableAreaText() {
  // @ts-expect-error
  const availableArea = useAppSelector(selectAvailableArea);
  const areaUnit = useAppSelector(selectAreaUnit);
  const formattedAvailableArea = convertNumberToFormattedString(availableArea.toFixed(2));
  const areaUnitLabel = getI18nAreaUnit(areaUnit);

  return (
    <Typography className="available-area-text">
      <Trans
        i18nKey="pricing.available-area"
        components={{
          1: (
            <span
              className={clsx(
                'available-area-text__text',
                availableArea > 0 ? 'text_highlight' : 'text_error',
              )}
            />
          ),
        }}
        values={{
          area: formattedAvailableArea,
          areaUnit: areaUnitLabel,
        }}
      />
      <Tooltip
        interactive
        classes={{
          label: 'available-area-text__tooltip-label',
          tooltip: 'available-area-text__tooltip',
        }}
        tooltip={(
          <Trans
            i18nKey="pricing.available-area-tooltip"
            values={{
              area: formattedAvailableArea,
              areaUnit: areaUnitLabel,
            }}
          />
        )}
      >
        <HelpOutlineIcon fontSize="small" />
      </Tooltip>
    </Typography>
  );
}
